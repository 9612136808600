import {IconButton, Menu, MenuItem, Typography} from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import LoopIcon from "@material-ui/icons/Loop"
import styled, {keyframes} from "styled-components"
import React, {useState} from "react"
import {api} from "@indebted/api"
import {useAgent} from "@indebted/hooks/Agent"
import {useNotification} from "@indebted/components/Notification"

function CustomerMenu({customer, reload}) {
	const [anchorEl, setAnchorEl] = useState()
	const [MenuIcon, setMenuIcon] = useState(<MoreVertIcon />)
	const [agentEmail] = useAgent()
	const {notification} = useNotification()

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleSnoozeItemClick = (durationID) => {
		handleClose()
		setMenuIcon(<LoadingIcon />)
		api.customer
			.snooze(customer.ID, {
				Duration: durationID,
				AgentEmail: agentEmail,
			})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(`Customer snoozed successfully.`)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})
	}

	const handleEscalateCaseItemClick = (escalationID) => {
		handleClose()
		setMenuIcon(<LoadingIcon />)
		api.customer
			.escalateCase(customer.ID, {
				Escalation: escalationID,
				AgentEmail: agentEmail,
			})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(`Customer case escalated successfully.`)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})
	}

	const handleCommsClick = (actionID, channel, labelAction) => {
		handleClose()
		setMenuIcon(<LoadingIcon />)
		api.customer
			.changeComms(customer.ID, {
				actionID,
				channel,
				AgentEmail: agentEmail,
			})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(`Customer ${labelAction} successful.`)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})
	}

	const handleDigitalCommunicationConsentClick = ({ID, Display}) => {
		handleClose()
		setMenuIcon(<LoadingIcon />)
		api.customer
			.changeDigitalCommConsent(customer.ID, {
				actionID: ID,
				AgentEmail: agentEmail,
			})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(`Customer digital comm consent was ${Display} successfully.`)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})
	}

	return (
		<>
			<IconButton disabled={!!customer.Escalation} size="small" onClick={handleClick}>
				{MenuIcon}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				getContentAnchorEl={null}
				anchorOrigin={{vertical: "bottom", horizontal: "right"}}
				transformOrigin={{vertical: "top", horizontal: "right"}}
				onClose={handleClose}
			>
				<MenuDivider color="textSecondary" variant="caption">
					Snooze
				</MenuDivider>
				{customer.SnoozeDurations.map((duration) => (
					<MenuItem key={duration.ID} onClick={() => handleSnoozeItemClick(duration.ID)}>
						{duration.Display}
					</MenuItem>
				))}
				<MenuDivider color="textSecondary" variant="caption">
					Escalation
				</MenuDivider>
				{customer.Escalations.map((escalation) => (
					<MenuItem key={escalation.ID} onClick={() => handleEscalateCaseItemClick(escalation.ID)}>
						{escalation.Display}
					</MenuItem>
				))}
				{customer.CommsOptions &&
					customer.CommsOptions.map((option, key) => {
						return [
							<MenuDivider key={key} color="textSecondary" variant="caption">
								{option.Label}
							</MenuDivider>,
							option.Items.map((item) => (
								<MenuItem
									key={item.ID}
									onClick={() => handleCommsClick(option.ID, item.ID, option.Label)}
								>
									{item.Display}
								</MenuItem>
							)),
						]
					})}
				{customer.DigitalCommunicationConsentOptions &&
					customer.DigitalCommunicationConsentOptions.map((option, key) => {
						return [
							<MenuDivider key={key} color="textSecondary" variant="caption">
								{option.Label}
							</MenuDivider>,
							option.Items.map((item) => (
								<MenuItem
									key={item.ID}
									onClick={() => {
										if (confirm(`Confirm set customer digital comm consent to ${item.Display}?`)) {
											handleDigitalCommunicationConsentClick(item)
										}
									}}
								>
									{item.Selected && <CheckIcon fontSize="small" />}
									{item.Display}
								</MenuItem>
							)),
						]
					})}
			</Menu>
		</>
	)
}

const loadingIconAnimations = keyframes`
	from { transform:rotate(0deg); }
    to { transform:rotate(-360deg);}
`

const LoadingIcon = styled(LoopIcon)`
	animation: ${loadingIconAnimations} 4s infinite linear;
`

const MenuDivider = styled(Typography)`
	padding-left: 6px;
`

export {CustomerMenu}
