import {Tooltip} from "@material-ui/core"
import React from "react"

function EmailStatusIcon({title = "", icon = ""}) {
	return (
		<Tooltip title={title}>
			<span> {icon}</span>
		</Tooltip>
	)
}

export {EmailStatusIcon}
