import React from "react"
import {Box, Typography} from "@material-ui/core"
import {Routes, Route, useParams} from "react-router-dom"

import {AccountList} from "./AccountList"
import {Account} from "./Account"
import {OutstandingAccountDetails} from "./OutstandingAccountDetails"
import {OtherAccountDetails} from "./OtherAccountDetails"
import {PaymentPlanAccountDetails} from "./PaymentPlanAccountDetails"

const OutstandingAccountHOC = withAccount(OutstandingAccountDetails)
const OtherAccountHOC = withAccount(OtherAccountDetails)
const PaymentPlanHOC = withAccount(PaymentPlanAccountDetails)

function Accounts({customer, reload}) {
	return (
		<Routes>
			<Route path="/" element={<AccountList customer={customer} reload={reload} />} />
			<Route
				path="/outstanding/:accountID"
				element={<OutstandingAccountHOC accounts={customer.OutstandingAccounts} reload={reload} />}
			/>
			<Route
				path="/payment-plan/:accountID"
				element={<PaymentPlanHOC accounts={customer.PaymentPlanAccounts} reload={reload} />}
			/>
			<Route
				path="/other/:accountID"
				element={<OtherAccountHOC accounts={customer.OtherAccounts} reload={reload} />}
			/>
		</Routes>
	)
}

function withAccount(Details) {
	return function AccountDetails({accounts, ...props}) {
		const {accountID} = useParams()
		const account = accounts.find(({ID}) => ID === accountID)
		if (!account) {
			return (
				<Box p={3} pt={5}>
					<Typography>404 Account not found</Typography>
				</Box>
			)
		}

		return <Account account={account} accountDetailsComponent={<Details account={account} {...props} />} />
	}
}

export {Accounts}
