import React from "react"
import {Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core"

import {AccountStatusIcon} from "./AccountStatusIcon"
import {AccountMenuTableCell} from "./AccountMenuTableCell"
import {OtherAccountMenu} from "./OtherAccountMenu"
import {AccountDetails} from "./AccountDetails"

const PaymentPlanAccountDetails = ({account, reload}) => (
	<Table size="small">
		<TableBody>
			<TableRow>
				<TableCell>
					<Typography variant="caption" color="textSecondary" display="block">
						Account Ref
					</Typography>
					<Typography variant="body2" display="block">
						<AccountStatusIcon status="InPaymentPlan" />
						{account.AccountRef}
					</Typography>
				</TableCell>
				<AccountMenuTableCell>
					<OtherAccountMenu account={account} reload={reload} />
				</AccountMenuTableCell>
			</TableRow>
			<AccountDetails
				details={account.Details}
				itemizationBalanceBreakdown={account.ItemizationBalanceBreakdown}
			/>
		</TableBody>
	</Table>
)

export {PaymentPlanAccountDetails}
