import React from "react"
import styled from "styled-components"

const LayoutStyle = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const FullHeight = styled.div`
	height: 100%;
`

export const Layout = ({children}) => {
	return (
		<LayoutStyle>
			<FullHeight>{children}</FullHeight>
		</LayoutStyle>
	)
}
