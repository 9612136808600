import styled from "styled-components"
import {TableCell} from "@material-ui/core"

const AccountMenuTableCell = styled(TableCell)`
	padding-left: 0px;
	width: 30px;

	:last-child {
		padding-right: 12px;
	}
`

export {AccountMenuTableCell}
