import {Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography} from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import {
	ExpandMore as ExpandMoreIcon,
	Email as CommunicationIcon,
	Info as InfoIcon,
	ThumbUp as ConfirmationIcon,
	Cancel as FailureIcon,
	CheckCircle as SuccessIcon,
	MonetizationOn as TransactionIcon,
	Warning as WarningIcon,
} from "@material-ui/icons"
import {green, orange, red, blue, lightGreen, grey} from "@material-ui/core/colors"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	summary: {
		fontSize: theme.typography.pxToRem(15),
		flexShrink: 0,
		paddingLeft: 8,
	},
	activityContent: {
		paddingLeft: 15,
	},
	activityContentLabel: {
		fontSize: theme.typography.pxToRem(15),
		flexShrink: 0,
	},
	activityContentValue: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
		paddingBottom: 15,
	},
}))

const icons = {
	Communication: <CommunicationIcon style={{color: grey[500]}} />,
	Confirmation: <ConfirmationIcon style={{color: lightGreen[500]}} />,
	Failure: <FailureIcon style={{color: red[500]}} />,
	Info: <InfoIcon style={{color: blue[800]}} />,
	Success: <SuccessIcon style={{color: green[500]}} />,
	Transaction: <TransactionIcon color="primary" />,
	Warning: <WarningIcon style={{color: orange[500]}} />,
}

function ActivitiesList({activities}) {
	const classes = useStyles()
	const [expandedPanel, setExpandedPanel] = React.useState(false)

	const handleExpand = (panel) => (_, currentlyExpanded) => {
		setExpandedPanel(currentlyExpanded ? panel : false)
	}

	const renderAccordionContent = (activity, panelId, expanded, icon) => (
		<Accordion expanded={expanded} onChange={handleExpand(panelId)}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				{icon}
				<Typography className={classes.summary}>
					<b>{activity.Title}</b> - {activity.HappenedAt}
				</Typography>
			</AccordionSummary>

			<AccordionDetails>
				{expanded && (
					<div className={classes.activityContent}>
						{activity["Fields"].map((field) => (
							<div key={field.Label}>
								<Typography className={classes.activityContentLabel}>{field.Label}</Typography>
								<Typography className={classes.activityContentValue}>{field.Value}</Typography>
							</div>
						))}
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)

	const renderAccordionNoContent = (activity, icon) => (
		<AccordionNoContent expanded={false}>
			<AccordionSummary>
				{icon}
				<Typography className={classes.summary}>
					<b>{activity.Title}</b> - {activity.HappenedAt}
				</Typography>
			</AccordionSummary>
		</AccordionNoContent>
	)

	const activityPanel = (activity, panelId) => {
		let expanded = expandedPanel === panelId

		const hasContent = activity["Fields"].length > 0
		const icon = icons[activity.IconType] || <InfoIcon color="primary" />
		return hasContent
			? renderAccordionContent(activity, panelId, expanded, icon)
			: renderAccordionNoContent(activity, icon)
	}

	return (
		<div className={classes.root}>
			{activities["Activities"].map((activity, panelId) => activityPanel(activity, panelId))}
		</div>
	)
}

const AccordionNoContent = styled(Accordion)`
	.MuiAccordionSummary-content {
		cursor: default;
	}
`

export {ActivitiesList}
