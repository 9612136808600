const {createTheme} = require("@material-ui/core/styles")
const {default: createSpacing} = require("@material-ui/core/styles/createSpacing")

const themeName = "InDebted"
const spacing = createSpacing(4)

// Use this tool for named colors, e.g. vulcanSlate
// http://chir.ag/projects/name-that-color/
const colors = {
	indebtedPrimary: "#474CDE",
	green: "#388E3C",
	red: "#B00020",
	waterLeaf: "#A0E9E3",
	stratos: "#020452",

	// slate
	vulcanSlate: "#12161F",
	slate: "#1B222F",

	// greyscale
	boulderGrey: "#7D7D7D",
	lightGrey: "#EFEFEF",
	white: "#FFFFFF",

	black: "#000000",
}

const palette = {
	primary: {main: colors.indebtedPrimary, contrastText: colors.white},
	secondary: {main: colors.slate, contrastText: colors.white},
	error: {main: colors.red, contrastText: colors.white},
}

const theme = createTheme({
	themeName,
	spacing,
	palette,
	props: {
		MuiButton: {
			color: "primary",
			variant: "contained",
		},
		MuiIconButton: {
			color: "primary",
		},
		MuiListItem: {
			dense: true,
		},
	},
	overrides: {
		MuiContainer: {
			root: {
				padding: spacing(3),
			},
		},
		MuiTableCell: {
			head: {
				color: colors.boulderGrey,
				fontSize: "0.875rem",
			},
			body: {
				padding: spacing(3),
			},
		},
		MuiAccordionSummary: {
			root: {
				paddingLeft: spacing(4),
				paddingRight: spacing(4),
			},
		},
	},
})

module.exports = {theme, colors}
