import {Box, Table, TableBody, Typography} from "@material-ui/core"
import React from "react"

function NoTransactions({children}) {
	return (
		<Table size="small">
			<TableBody>
				<Box display="flex" justifyContent="center" alignItems="center">
					<Typography variant="caption" color="textSecondary">
						{children}
					</Typography>
				</Box>
			</TableBody>
		</Table>
	)
}

export {NoTransactions}
