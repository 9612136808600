import React, {useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom"
import styled from "styled-components"
import {colors} from "@indebted/theme"
import ErrorIcon from "@material-ui/icons/Error"
import SearchIcon from "@material-ui/icons/Search"
import {Box, Grid, InputAdornment, TextField} from "@material-ui/core"

import {LoadingIcon} from "./Loading"
import {BackToSearch} from "./BackToSearch"

function Search({value, backURL, loading, error}) {
	const [form, setForm] = useState({q: value})
	const inputEl = useRef(null)
	const navigate = useNavigate()

	const handleInput = ({target: {value}}) => {
		setForm({...form, q: value})
	}

	useEffect(() => {
		const listener = (event) => {
			if (event.key === "/" && document.activeElement !== inputEl.current) {
				inputEl.current.select()
			}
		}

		document.addEventListener("keyup", listener)
		return () => document.removeEventListener("keyup", listener)
	})

	const submit = (e) => {
		e.preventDefault()
		navigate(`/customers?q=${encodeURIComponent(form.q)}`)
	}

	let adornment = (
		<InputAdornment position="end">
			<SearchIcon />
		</InputAdornment>
	)

	if (loading) {
		adornment = (
			<InputAdornment position="end">
				<LoadingIcon width="1.5rem" />
			</InputAdornment>
		)
	}

	if (error) {
		adornment = (
			<InputAdornment position="end">
				<StyledErrorIcon />
			</InputAdornment>
		)
	}

	return (
		<Box p={3}>
			<Grid container justifyContent="flex-end">
				<Grid item xs={12}>
					<form onSubmit={submit}>
						<TextField
							label="Search"
							inputProps={{ref: inputEl}}
							value={form.q}
							onChange={handleInput}
							InputProps={{
								"data-lpignore": "true",
								endAdornment: adornment,
							}}
							variant="outlined"
							disabled={loading}
							error={!!error}
							helperText={error}
							fullWidth
							autoFocus
						/>
					</form>
				</Grid>
				<Grid item>
					<BackToSearch backURL={backURL} />
				</Grid>
			</Grid>
		</Box>
	)
}

const StyledErrorIcon = styled(ErrorIcon)`
	color: ${colors.red};
`

export {Search}
