import React from "react"
import {Accordion, AccordionSummary, Grid, Typography} from "@material-ui/core"

import eSignConsentGrantedIcon from "./icons/eSignConsentGranted.svg"

function ESIGNConsentGrantedActivity(panelId, {Title, HappenedAt}, expanded, classes) {
	return (
		<Accordion expanded={expanded}>
			<AccordionSummary>
				<Grid style={{padding: "0 2px"}}>
					<img src={eSignConsentGrantedIcon} width={20} />
				</Grid>
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt}
				</Typography>
			</AccordionSummary>
		</Accordion>
	)
}

export {ESIGNConsentGrantedActivity}
