import {Box, Button, Grid, Modal, Paper, Typography, Link} from "@material-ui/core"
import React, {useState} from "react"
import {styled} from "@mui/material/styles"
import {api} from "@indebted/api"
import {useNotification} from "@indebted/components/Notification"
import {useAgent} from "@indebted/hooks/Agent"

function ESIGNConsentRequestModal({customerID, reload, linkText}) {
	const [open, setOpen] = useState(false)
	const {notification} = useNotification()
	const [agentEmail] = useAgent()

	const requestESIGNConsent = () => {
		api.customer
			.requestESIGNConsent(customerID, agentEmail)
			.then(() => {
				notification.success(`E-Sign consent request email sent.`)
				setTimeout(reload, 1000)
			})
			.catch((error) => {
				notification.error(error.Message)
			})
		setOpen(false)
	}

	return (
		<>
			<Link component="button" variant="body2" onClick={() => setOpen(true)}>
				{linkText}
			</Link>
			<Modal
				open={open ?? false}
				onClose={() => setOpen(false)}
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
			>
				<StyledPaper>
					<Box style={{width: "100%"}}>
						<Grid container direction="column" spacing={3}>
							<Grid item>
								<Grid
									container
									direction="row"
									spacing={2}
									style={{alignItems: "center", justifyContent: "flex-start"}}
								>
									<Grid item>
										<Typography variant="h5">
											Are you sure you want to request E-Sign consent?
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant="p" style={{color: "rgba(0, 0, 0, .5)"}}>
											An automatic email will be sent to the customer.
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Button fullWidth onClick={requestESIGNConsent}>
									<Typography variant="body1">Request E-Sign consent</Typography>
								</Button>
							</Grid>
							<Grid item>
								<Button fullWidth variant={"outlined"} onClick={() => setOpen(false)}>
									<Typography variant="body1">Back</Typography>
								</Button>
							</Grid>
						</Grid>
					</Box>
				</StyledPaper>
			</Modal>
		</>
	)
}

const StyledPaper = styled(Paper)`
	padding: 20px;
	position: relative;
	width: 400px;
	max-height: 95%;
	outline: none;
	overflow: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;

	@media screen and (max-width: 599px) {
		width: 90%;
	}
`

export {ESIGNConsentRequestModal}
