import React, {useEffect, useState} from "react"
import {api} from "@indebted/api"
import {LoadingPage} from "@indebted/components/Loading"

import {NoActivities} from "./NoActivities"
import {List} from "./List"

function Activities({customerID}) {
	const [Component, setComponent] = useState(<LoadingPage />)

	useEffect(() => {
		api.customer
			.activities(customerID)
			.then((activities) => {
				if (activities.length === 0) {
					setComponent(<NoActivities>No activities</NoActivities>)
				} else {
					setComponent(<List activities={activities} />)
				}
			})
			.catch(() => {
				setComponent(<NoActivities>Something went wrong</NoActivities>)
			})
	}, [customerID])

	return Component
}

export {Activities}
