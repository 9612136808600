import {Box, Button, Grid, IconButton, Modal, Paper, Typography, Tabs, Tab} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {styled} from "@mui/material/styles"
import {api} from "@indebted/api"
import {useNotification} from "@indebted/components/Notification"
import CloseIcon from "@material-ui/icons/Close"

function CustomerEligibleOffersModal({customerID}) {
	const [open, setOpen] = useState(false)
	const {notification} = useNotification()
	const [tabIndex, setTabIndex] = useState(0)
	const [offerData, setOfferData] = useState({})

	useEffect(() => {
		api.customer
			.getEligibleOffers(customerID)
			.then((response) => {
				setOfferData(response)
				if (!response.InFull) setTabIndex(1)
			})
			.catch((error) => {
				notification.error(error.Message)
			})
	}, [customerID, notification])

	return (
		<>
			{offerData.InFull || offerData.PaymentPlan ? (
				<>
					<Button
						size="small"
						variant="outlined"
						color="primary"
						onClick={() => setOpen(true)}
						disabled={!offerData.InFull && !offerData.PaymentPlan}
					>
						View offer
					</Button>
					<Modal
						open={open ?? false}
						onClose={() => setOpen(false)}
						aria-labelledby="modal-title"
						aria-describedby="modal-description"
					>
						<StyledPaper>
							<Box style={{width: "100%"}}>
								<Grid container direction="column" spacing={3}>
									<Grid item style={{paddingBottom: "0"}}>
										<Grid
											container
											direction="row"
											spacing={3}
											style={{alignItems: "center", justifyContent: "flex-start"}}
										>
											<Grid
												item
												xs={9}
												sm={10}
												style={{paddingLeft: "12px", paddingTop: "18px", paddingBottom: "0"}}
											>
												<Typography
													variant="h5"
													style={{fontWeight: "bold", lineHeight: 1.3}}
													align="left"
												>
													Settlement offers
												</Typography>
											</Grid>
											<Grid
												item
												xs={3}
												sm={2}
												style={{display: "flex", justifyContent: "flex-end"}}
											>
												<IconButton aria-label="close" onClick={() => setOpen(false)}>
													<CloseIcon htmlColor="rgba(50,50,50,0.45)" />
												</IconButton>
											</Grid>
										</Grid>
									</Grid>
									<Grid item style={{paddingTop: "0"}}>
										<Tabs
											value={tabIndex}
											onChange={(e, newValue) => setTabIndex(newValue)}
											indicatorColor="primary"
											centered={true}
										>
											<StyledTab
												label="Settle in full"
												id="settlement-offer-0"
												aria-controls="settlement-offer-0"
												disabled={!offerData.InFull}
											/>
											<StyledTab
												label="Settle in a plan"
												id="settlement-offer-1"
												aria-controls="settlement-offer-1"
												disabled={!offerData.PaymentPlan}
											/>
										</Tabs>
									</Grid>
									<Grid item style={{paddingTop: "5px"}}>
										<CustomTabPanel value={tabIndex} index={0}>
											<>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Pay until:
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																variant="subtitle1"
																align="right"
																color="textSecondary"
															>
																{offerData.InFull?.ExpireDate}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Full balance:
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																variant="subtitle1"
																align="right"
																color="textSecondary"
															>
																{offerData.InFull?.Balance}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Discount percentage:
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																variant="subtitle1"
																align="right"
																color="textSecondary"
															>
																{offerData.InFull?.DiscountPercentage}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Discounted amount:
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																variant="subtitle1"
																align="right"
																color="textSecondary"
															>
																{offerData.InFull?.DiscountedAmount}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Amount to pay:
															</Typography>
														</Grid>
														<Grid item>
															<Typography variant="subtitle1" align="right">
																{offerData.InFull?.AmountToPay}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											</>
										</CustomTabPanel>
										<CustomTabPanel value={tabIndex} index={1}>
											<>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Pay until:
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																variant="subtitle1"
																align="right"
																color="textSecondary"
															>
																{offerData.PaymentPlan?.ExpireDate}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Settlement limit date:
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																variant="subtitle1"
																align="right"
																color="textSecondary"
															>
																{offerData.PaymentPlan?.SettlementLimitDate}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Full balance:
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																variant="subtitle1"
																align="right"
																color="textSecondary"
															>
																{offerData.PaymentPlan?.Balance}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Discount percentage:
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																variant="subtitle1"
																align="right"
																color="textSecondary"
															>
																{offerData.PaymentPlan?.DiscountPercentage}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Discounted amount:
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																variant="subtitle1"
																align="right"
																color="textSecondary"
															>
																{offerData.PaymentPlan?.DiscountedAmount}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item style={{padding: "2px 2px 2px 6px"}}>
													<Grid container direction="row" justifyContent="space-between">
														<Grid item>
															<Typography
																variant="subtitle1"
																align="left"
																color="textSecondary"
															>
																Amount to pay:
															</Typography>
														</Grid>
														<Grid item>
															<Typography variant="subtitle1" align="right">
																{offerData.PaymentPlan?.AmountToPay}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											</>
										</CustomTabPanel>
									</Grid>
								</Grid>
							</Box>
						</StyledPaper>
					</Modal>
				</>
			) : null}
		</>
	)
}

function CustomTabPanel(props) {
	const {children, value, index, ...other} = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`settlement-offer-${index}`}
			aria-labelledby={`settlement-offer-${index}`}
			{...other}
		>
			{value === index && <Grid>{children}</Grid>}
		</div>
	)
}

const StyledPaper = styled(Paper)`
	padding: 10px 20px 30px 20px;
	position: relative;
	width: 450px;
	max-height: 95%;
	outline: none;
	overflow: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@media screen and (max-width: 599px) {
		width: 90%;
	}
`

const StyledTab = styled(Tab)({
	display: "flex",
	flex: 1,
	textTransform: "none",
	borderBottom: "2px solid rgba(0, 0, 0, 1)",
	color: "rgba(0, 0, 0, 1)",

	"&.Mui-selected": {
		color: "#474CDE",
	},

	"&.Mui-disabled": {
		color: "rgba(0, 0, 0, 0)",
	},
})

export {CustomerEligibleOffersModal}
