import React, {useState} from "react"
import {Box, Button, Grid, Paper, TextField} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import {api} from "@indebted/api"
import {useNotification} from "@indebted/components/Notification"
import {useAgent} from "@indebted/hooks/Agent"
import Autolink from "autolinker"
import styled from "styled-components"

const localTimeOptions = {
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
	hour12: true,
	timeZoneName: "short",
}

function toLocalTime(epoch) {
	return new Intl.DateTimeFormat("default", localTimeOptions).format(new Date(epoch))
}

function NoteItemCard({note}) {
	return (
		<Paper elevation={2}>
			<Box p={4}>
				<Grid direction="column" container spacing={2}>
					<Grid item container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Typography color="textSecondary" variant="subtitle2">
								<time>{toLocalTime(note.CreatedAt)}</time>
							</Typography>
						</Grid>
						<Grid item>
							<Typography color="textSecondary" variant="subtitle2">
								{note.AgentName}
							</Typography>
						</Grid>
					</Grid>

					<Grid item>
						<NoteTypography variant="body2" dangerouslySetInnerHTML={{__html: Autolink.link(note.Body)}} />
					</Grid>
				</Grid>
			</Box>
		</Paper>
	)
}

const NoteTypography = styled(Typography)`
	white-space: break-spaces;

	a {
		word-break: break-all;
	}
`

function Notes({customerID, notes, reload}) {
	const {notification} = useNotification()
	const [agentEmail, agentName] = useAgent()
	const [noteBody, setNoteBody] = useState()

	const handleAddNote = (noteBody) => {
		api.customer
			.addNote(customerID, {
				Body: noteBody,
				AgentEmail: agentEmail,
				AgentName: agentName,
			})
			.then(() => {
				notification.success(
					`Note added successfully. Please wait up to 48 hours for propagation in all systems.`,
				)

				setNoteBody("")
				setTimeout(reload, 500)
			})
			.catch((error) => {
				notification.error(error.Message)
			})
	}

	return (
		<Box p={3}>
			<Grid direction="column" spacing={2} container>
				<Grid item>
					<TextField
						multiline
						minRows={4}
						maxRows={50}
						placeholder="Add a note about the customer"
						variant="outlined"
						fullWidth
						value={noteBody}
						onChange={(el) => setNoteBody(el.target.value)}
					/>
				</Grid>
				<Grid item>
					<Grid container justifyContent="flex-end" spacing={2}>
						<Grid item>
							<Button disabled={!noteBody} onClick={() => handleAddNote(noteBody)}>
								Add Note
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Typography variant="subtitle1">{notes.length === 0 ? "No notes" : "Notes"}</Typography>
			<Grid direction="column" spacing={2} container>
				{notes.map((note, idx) => (
					<Grid key={idx} item>
						<NoteItemCard note={note} />
					</Grid>
				))}
			</Grid>
		</Box>
	)
}

export {Notes}
