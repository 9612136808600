import {IconButton, Menu, MenuItem, Typography} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import LoopIcon from "@material-ui/icons/Loop"
import styled, {keyframes} from "styled-components"
import React, {useState} from "react"
import {api} from "@indebted/api"
import {useAgent} from "@indebted/hooks/Agent"
import {useNotification} from "@indebted/components/Notification"

function AccountMenu({account, reload}) {
	const [agentEmail] = useAgent()
	const {notification} = useNotification()
	const [anchorEl, setAnchorEl] = useState()
	const [MenuIcon, setMenuIcon] = useState(<MoreVertIcon />)

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleMenuItemClick = (disputeReason) => {
		setMenuIcon(<LoadingIcon />)

		api.account
			.dispute(account.ID, {
				Reason: disputeReason.ID,
				AgentEmail: agentEmail,
			})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(
					`Account disputed successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})

		handleClose()
	}

	const handleRecallClick = () => {
		setMenuIcon(<LoadingIcon />)

		api.account
			.recall(account.ID, {
				AgentEmail: agentEmail,
			})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(
					`Account recalled successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})

		handleClose()
	}

	return (
		<>
			<IconButton size="small" onClick={handleClick}>
				{MenuIcon}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				getContentAnchorEl={null}
				anchorOrigin={{vertical: "bottom", horizontal: "right"}}
				transformOrigin={{vertical: "top", horizontal: "right"}}
				onClose={handleClose}
			>
				<Divider color="textSecondary" variant="caption">
					Dispute
				</Divider>
				{account.DisputeReasons.map((disputeReason) => (
					<MenuItem key={disputeReason.ID} onClick={() => handleMenuItemClick(disputeReason)}>
						{disputeReason.Reason}
					</MenuItem>
				))}
				<Divider color="textSecondary" variant="caption">
					Recall
				</Divider>
				<MenuItem onClick={handleRecallClick}>Recall account</MenuItem>
			</Menu>
		</>
	)
}

const loadingIconAnimations = keyframes`
	from { transform:rotate(0deg); }
    to { transform:rotate(-360deg);}
`

const LoadingIcon = styled(LoopIcon)`
	animation: ${loadingIconAnimations} 4s infinite linear;
`

const Divider = styled(Typography)`
	padding: 0 6px;
`

export {AccountMenu}
