import {IconButton, Menu, MenuItem, Typography} from "@material-ui/core"
import React, {useState} from "react"
import styled, {keyframes} from "styled-components"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import LoopIcon from "@material-ui/icons/Loop"
import {api} from "@indebted/api"
import {useAgent} from "@indebted/hooks/Agent"
import {useNotification} from "@indebted/components/Notification"

function OtherAccountMenu(props) {
	const {account} = props

	const map = {
		NotificationOfAssignmentPending: NotificationOfAssignmentPendingMenu,
		NotificationOfAssignmentPendingDueToEmailScrub: NotificationOfAssignmentPendingDueToEmailScrubMenu,
		NotificationOfAssignmentPermanentlyUndelivered: NotificationOfAssignmentUndeliveredMenu,
		NotificationOfAssignmentTemporarilyUndelivered: NotificationOfAssignmentUndeliveredMenu,
	}

	const Component = map[account.Status] || DisabledMenu
	return <Component {...props} />
}

function DisabledMenu() {
	return <MoreVertIcon color="disabled" />
}

function NotificationOfAssignmentPendingMenu({account, reload}) {
	const [agentEmail] = useAgent()
	const {notification} = useNotification()
	const [anchorEl, setAnchorEl] = useState()
	const [MenuIcon, setMenuIcon] = useState(<MoreVertIcon />)

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleMenuItemClick = () => {
		setMenuIcon(<LoadingIcon />)

		api.account
			.acknowledgeNotificationOfAssignment(account.ID, {AgentEmail: agentEmail})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(
					`Notification of Assignment acknowledged successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 1000)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})

		handleClose()
	}

	const handleRecallClick = () => {
		setMenuIcon(<LoadingIcon />)

		api.account
			.recall(account.ID, {
				AgentEmail: agentEmail,
			})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(
					`Account recalled successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})

		handleClose()
	}

	return (
		<>
			<IconButton size="small" onClick={handleClick}>
				{MenuIcon}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				getContentAnchorEl={null}
				anchorOrigin={{vertical: "bottom", horizontal: "right"}}
				transformOrigin={{vertical: "top", horizontal: "right"}}
				onClose={handleClose}
			>
				<Divider color="textSecondary" variant="caption">
					Notification of assignment
				</Divider>
				<MenuItem onClick={() => handleMenuItemClick()}>Acknowledge</MenuItem>
				<Divider color="textSecondary" variant="caption">
					Recall
				</Divider>
				<MenuItem onClick={handleRecallClick}>Recall account</MenuItem>
			</Menu>
		</>
	)
}

function NotificationOfAssignmentPendingDueToEmailScrubMenu({account, reload}) {
	const [agentEmail] = useAgent()
	const {notification} = useNotification()
	const [anchorEl, setAnchorEl] = useState()
	const [MenuIcon, setMenuIcon] = useState(<MoreVertIcon />)

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleRecallClick = () => {
		setMenuIcon(<LoadingIcon />)

		api.account
			.recall(account.ID, {
				AgentEmail: agentEmail,
			})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(
					`Account recalled successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})

		handleClose()
	}

	return (
		<>
			<IconButton size="small" onClick={handleClick}>
				{MenuIcon}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				getContentAnchorEl={null}
				anchorOrigin={{vertical: "bottom", horizontal: "right"}}
				transformOrigin={{vertical: "top", horizontal: "right"}}
				onClose={handleClose}
			>
				<Divider color="textSecondary" variant="caption">
					Recall
				</Divider>
				<MenuItem onClick={handleRecallClick}>Recall account</MenuItem>
			</Menu>
		</>
	)
}

function NotificationOfAssignmentUndeliveredMenu({account, reload}) {
	const [agentEmail] = useAgent()
	const {notification} = useNotification()
	const [anchorEl, setAnchorEl] = useState()
	const [MenuIcon, setMenuIcon] = useState(<MoreVertIcon />)

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleMenuItemClick = () => {
		setMenuIcon(<LoadingIcon />)

		api.account
			.reTriggerNotificationOfAssignment(account.ID, {AgentEmail: agentEmail})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(
					`Notification of Assignment re-triggered successfully. Please wait up to 24 hours for the email.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})

		handleClose()
	}

	const handleRecallClick = () => {
		setMenuIcon(<LoadingIcon />)

		api.account
			.recall(account.ID, {
				AgentEmail: agentEmail,
			})
			.then(() => {
				setMenuIcon(<MoreVertIcon />)
				notification.success(
					`Account recalled successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				setMenuIcon(<MoreVertIcon />)
				notification.error(error.Message)
			})

		handleClose()
	}

	return (
		<>
			<IconButton size="small" onClick={handleClick}>
				{MenuIcon}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				getContentAnchorEl={null}
				anchorOrigin={{vertical: "bottom", horizontal: "right"}}
				transformOrigin={{vertical: "top", horizontal: "right"}}
				onClose={handleClose}
			>
				<Divider color="textSecondary" variant="caption">
					Notification of assignment
				</Divider>
				<MenuItem onClick={() => handleMenuItemClick()}>Re-trigger</MenuItem>
				<Divider color="textSecondary" variant="caption">
					Recall
				</Divider>
				<MenuItem onClick={handleRecallClick}>Recall account</MenuItem>
			</Menu>
		</>
	)
}

const loadingIconAnimations = keyframes`
	from { transform:rotate(0deg); }
    to { transform:rotate(-360deg);}
`

const LoadingIcon = styled(LoopIcon)`
	animation: ${loadingIconAnimations} 4s infinite linear;
`

const Divider = styled(Typography)`
	padding: 0 6px;
`

export {OtherAccountMenu}
