import {useLocation} from "react-router-dom"
import {Search} from "@indebted/components/Search"
import React from "react"

function CustomerError({error}) {
	const location = useLocation()
	const search = new URLSearchParams(location.search)

	return <Search value={search.get("q")} error={error} />
}

export {CustomerError}
