class API {
	constructor() {
		this.customer = new Customer()
		this.account = new Account()
		this.paymentPlan = new PaymentPlan()
		this.url = (path) => apiBaseUrl() + path
	}
}

function RelativeURL(path) {
	const defaultBase = "https://path"
	const url = new URL(path, defaultBase)

	function appendQueryParam(name, value) {
		const newURL = new URL(url)
		newURL.searchParams.append(name, value)

		return RelativeURL(newURL.toString().replace(defaultBase, ""))
	}

	function toString() {
		return url.toString().replace(defaultBase, "")
	}

	return {appendQueryParam, toString}
}

class Customer {
	search(query) {
		return get(`/customers?q=${encodeURIComponent(query)}`)
	}

	get(id, viewerTimezoneOffset) {
		return get(`/customers/${id}?ViewerTimezoneOffsetInMinutes=${viewerTimezoneOffset}`)
	}

	snooze(id, payload) {
		return post(`/customers/${id}/snooze`, payload)
	}

	escalateCase(id, payload) {
		return post(`/customers/${id}/escalate-case`, payload)
	}

	addEmail(id, payload) {
		return post(`/customers/${id}/add-email`, payload)
	}

	changeAddress(id, payload) {
		return post(`/customers/${id}/change-address`, payload)
	}

	removeEmail(id, payload) {
		return post(`/customers/${id}/remove-email`, payload)
	}

	removeEmailLead(id, payload) {
		return post(`/customers/${id}/remove-email-lead`, payload)
	}

	addPhone(id, payload) {
		return post(`/customers/${id}/add-phone`, payload)
	}

	removePhone(id, payload) {
		return post(`/customers/${id}/remove-phone`, payload)
	}

	changeComms(id, {actionID, channel, ...payload}) {
		return post(`/customers/${id}/${actionID}/${channel}`, payload)
	}

	changeDigitalCommConsent(id, {actionID, ...payload}) {
		return post(`/customers/${id}/digital-communication-consent/${actionID}`, payload)
	}

	addNote(customerID, payload) {
		return post(`/customers/${customerID}/add-note`, payload)
	}

	generatePaymentLink(customerID) {
		return post(`/customers/${customerID}/payment-link`)
	}

	activities(customerID) {
		return get(`/customers/${customerID}/activities`)
	}

	settlementOfferCreate(customerID, payload) {
		return post(`/customers/${customerID}/settlement-offer`, payload)
	}

	settlementOfferCalculate(customerID, payload) {
		return post(`/customers/${customerID}/settlement-offer/calculate`, payload)
	}

	getSettlementOffer(customerID) {
		return get(`/customers/${customerID}/settlement-offer`)
	}

	getEligibleOffers(customerID) {
		return get(`/customers/${customerID}/eligible-offers`)
	}

	requestESIGNConsent(customerID, agentEmail) {
		return post(`/customers/${customerID}/request-esign-consent`, {
			AgentEmail: agentEmail,
		})
	}

	revokeESIGNConsent(customerID, revokedAt, revokedVia, agentEmail) {
		return post(`/customers/${customerID}/revoke-esign-consent`, {
			RevokedAt: revokedAt,
			RevokedVia: revokedVia,
			AgentEmail: agentEmail,
		})
	}
}

class Account {
	dispute(id, dispute) {
		return post(`/accounts/${id}/dispute`, dispute)
	}

	acknowledgeNotificationOfAssignment(id, payload) {
		return post(`/accounts/${id}/acknowledge-notification-of-assignment`, payload)
	}

	reTriggerNotificationOfAssignment(id, payload) {
		return post(`/accounts/${id}/re-trigger-notification-of-assignment`, payload)
	}

	recall(id, payload) {
		return post(`/accounts/${id}/recall`, payload)
	}

	activities(id) {
		return get(`/accounts/${id}/activities`)
	}
}

class PaymentPlan {
	cancel(id, payload) {
		return post(`/payment-plans/${id}/cancel`, payload)
	}
}

function get(endpoint, options = {}) {
	return req(
		RelativeURL(endpoint).appendQueryParam("AgentEmail", window.localStorage.getItem("agentEmail")).toString(),
		{...options, method: "GET"},
	)
}

function post(endpoint, body, options = {}) {
	return req(endpoint, {...options, method: "POST", body: JSON.stringify(body)})
}

function req(endpoint, options) {
	return new Promise((resolve, reject) => {
		fetch(apiBaseUrl() + endpoint, options)
			.then((res) => {
				const contentType = res.headers.get("Content-Type")
				const isJSON = contentType && contentType.includes("application/json")
				const promise = isJSON ? res.json() : res.text()
				promise.then((body) => (res.status >= 400 ? reject(apiError(body)) : resolve(body)))
			})
			.catch(() => {
				reject(apiError("Network error"))
			})
	})
}

function apiBaseUrl() {
	return process.env.API_URL
}

function apiError(error) {
	const unknownError = {Message: "Unknown error"}
	if (!error) return unknownError
	if (typeof error == "string") return {Message: error}
	if (error.message) return {Message: error.message}
	if (error.Message) return error
	if (error.Errors) {
		const message = Object.keys(error.Errors)
			.map((key) => {
				return error.Errors[key].map((msg) => `${key} ${msg}`)
			})
			.join(".")
		return {Message: message}
	}
	return unknownError
}

const api = new API()
module.exports = {api}
