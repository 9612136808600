import {Button} from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import {useNavigate} from "react-router-dom"
import React from "react"

function BackToSearch({backURL}) {
	const navigate = useNavigate()
	const map = {
		true: (
			<Button
				variant="text"
				size="small"
				style={{padding: 0}}
				startIcon={<ArrowBackIcon />}
				onClick={() => navigate(backURL)}
			>
				Back
			</Button>
		),
		false: null,
	}

	return map[!!backURL]
}

export {BackToSearch}
