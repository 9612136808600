import {IconButton, Tooltip} from "@material-ui/core"
import CopyIcon from "@material-ui/icons/FileCopyOutlined"
import React from "react"

import {useNotification} from "./Notification"

function CopyButton({content = ""}) {
	const {notification} = useNotification()

	return (
		<Tooltip title="Copy">
			<IconButton
				color="default"
				edge="start"
				size="small"
				onClick={() => {
					navigator.clipboard.writeText(content).catch(() => {
						notification.error("Failed to copy to clipboard. Please manually copy the content.")
					})
				}}
			>
				<CopyIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	)
}

export {CopyButton}
