import styled from "styled-components"
import React from "react"
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core"
import {ExpandMore as ExpandMoreIcon, Email as EmailIcon} from "@material-ui/icons"
import {api} from "@indebted/api"

function EmailSentActivity(panelId, {Title, HappenedAt, Payload}, expanded, handleExpand, classes) {
	return (
		<Accordion expanded={expanded} onChange={handleExpand(panelId)}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<EmailIcon color="primary" />
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt} {Payload.Summary}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{expanded && <StyledIFrame allowFullScreen src={api.url(Payload.ContentURI)} />}
			</AccordionDetails>
		</Accordion>
	)
}

const StyledIFrame = styled.iframe`
	width: 100%;
	flex-grow: 1;
	height: 600px;
	border: none;
`

export {EmailSentActivity}
