import React from "react"
import {Tooltip} from "@material-ui/core"

function Truncated({text, size = 15}) {
	return (
		<Tooltip title={text} placement="top">
			<span>{truncate(text, size)}</span>
		</Tooltip>
	)
}

function truncate(text, max, ending = "…") {
	return text.length > max ? text.substr(0, max - ending.length) + ending : text
}

export {Truncated}
