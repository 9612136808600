import React from "react"
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core"
import {ExpandMore as ExpandMoreIcon, AddCircle as AddEmailLeadIcon} from "@material-ui/icons"

function EmailLeadsAddedActivity(panelId, {Title, HappenedAt, Payload}, expanded, handleExpand, classes) {
	return (
		<Accordion expanded={expanded} onChange={handleExpand(panelId)}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<AddEmailLeadIcon style={{color: "#FF9417"}} />
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{expanded && (
					<div className={classes.activityContent}>
						{Payload.Emails && (
							<div>
								<Typography className={classes.activityContentLabel}>Emails:</Typography>
								<ul>
									{Payload.Emails.map((email, index) => (
										<li key={index}>
											<Typography className={classes.activityContentValue}>{email}</Typography>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export {EmailLeadsAddedActivity}
