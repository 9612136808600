import {Box, Modal, Paper} from "@material-ui/core"
import React from "react"
import {styled} from "@mui/material/styles"
import {api} from "@indebted/api"
import {useNotification} from "@indebted/components/Notification"

import {OfferModalForm} from "./OfferModalForm"
import {OfferModalReview} from "./OfferModalReview"

function OfferModal({offerData, customerID}) {
	const [Component, setComponent] = React.useState(<></>)
	const [open, setOpen] = React.useState(false)
	const {notification} = useNotification()

	React.useEffect(() => {
		function createDiscountOffer(date, percentage) {
			api.customer
				.settlementOfferCreate(customerID, {
					CampaignEndDate: date,
					Discount: percentage,
					AccountIDs: offerData.Accounts,
				})
				.then(() => {
					offerData.open = false
					setOpen(false)
					notification.success(`Offer created successfully.`)
				})
				.catch((error) => {
					notification.error(error.Message)
					offerData.open = false
					setOpen(false)
				})
		}
		function calculateDiscountedAmount(date, percentage) {
			api.customer
				.settlementOfferCalculate(customerID, {
					CampaignEndDate: date,
					Discount: percentage,
					AccountIDs: offerData.Accounts,
				})
				.then((response) => {
					setComponent(
						<OfferModalReview
							offerData={response}
							onCancel={() => setOpen(false)}
							onSubmit={() => createDiscountOffer(date, percentage)}
							onBack={() =>
								setComponent(
									<OfferModalForm
										onCancel={() => setOpen(false)}
										onCalculate={calculateDiscountedAmount}
										offerData={offerData}
										storedData={{date, percentage}}
									/>,
								)
							}
						/>,
					)
				})
				.catch((error) => {
					setComponent(
						<OfferModalForm
							offerData={offerData}
							onCalculate={calculateDiscountedAmount}
							onCancel={() => setOpen(false)}
							error={error.Message}
						/>,
					)
				})
		}
		setOpen(offerData.open)
		setComponent(
			<OfferModalForm
				onCancel={() => setOpen(false)}
				onCalculate={calculateDiscountedAmount}
				offerData={offerData}
			/>,
		)
	}, [offerData, customerID, notification, offerData.open])

	return (
		<Modal
			open={open ?? false}
			onClose={() => setOpen(false)}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<StyledPaper>
				<Box style={{width: "100%"}}>{Component}</Box>
			</StyledPaper>
		</Modal>
	)
}

const StyledPaper = styled(Paper)`
	padding: 10px 20px 30px 20px;
	position: relative;
	width: 450px;
	max-height: 95%;
	outline: none;
	overflow: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@media screen and (max-width: 599px) {
		width: 90%;
	}
`

export {OfferModal}
