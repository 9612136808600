import React from "react"

import {NoTransactions} from "./NoTransactions"
import {List} from "./List"

function Transactions({transactions}) {
	if (transactions.length === 0) {
		return <NoTransactions>No Transactions</NoTransactions>
	}
	return <List Transactions={transactions} />
}

export {Transactions}
