import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {api} from "@indebted/api"
import {LoadingPage} from "@indebted/components/Loading"
import {Layout} from "@indebted/components/Layout"

import {CustomerCard} from "./CustomerCard"
import {CustomerError} from "./CustomerError"

function CustomerPage() {
	const [Component, setComponent] = useState(<LoadingPage />)
	const {id, viewerTimezoneOffset = new Date().getTimezoneOffset()} = useParams()

	useEffect(load, [id, viewerTimezoneOffset]) // eslint-disable-line react-hooks/exhaustive-deps

	function load() {
		setComponent(<LoadingPage />)
		api.customer
			.get(id, viewerTimezoneOffset)
			.then((customer) => {
				setComponent(<CustomerCard customer={customer} reload={load} />)
			})
			.catch((error) => {
				setComponent(<CustomerError error={error.Message} />)
			})
	}

	return <Layout>{Component}</Layout>
}

export {CustomerPage}
