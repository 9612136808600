import React from "react"
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core"
import {ExpandMore as ExpandMoreIcon, PersonAdd as PersonIcon} from "@material-ui/icons"

function CreatedActivity(panelId, {Title, HappenedAt, Payload}, expanded, handleExpand, classes) {
	return (
		<Accordion expanded={expanded} onChange={handleExpand(panelId)}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<PersonIcon color="primary" />
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{expanded && (
					<div className={classes.activityContent}>
						<div>
							<Typography className={classes.activityContentLabel}>Full Name:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.Name}</Typography>
						</div>
						<div>
							<Typography className={classes.activityContentLabel}>Date of Birth:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.DOB}</Typography>
						</div>
						<div>
							<Typography className={classes.activityContentLabel}>Address:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.Address}</Typography>
						</div>
						<div>
							<Typography className={classes.activityContentLabel}>Emails:</Typography>
							{Payload.Emails.length === 0 ? (
								<Typography className={classes.activityContentValue}>Not Available</Typography>
							) : (
								<ul>
									{Payload.Emails.map((email, index) => (
										<li key={index}>
											<Typography className={classes.activityContentValue}>{email}</Typography>
										</li>
									))}
								</ul>
							)}
						</div>
						<div>
							<Typography className={classes.activityContentLabel}>Phones:</Typography>
							{Payload.Phones.length === 0 ? (
								<Typography className={classes.activityContentValue}>Not Available</Typography>
							) : (
								<ul>
									{Payload.Phones.map((phone, index) => (
										<li key={index}>
											<Typography className={classes.activityContentValue}>{phone}</Typography>
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export {CreatedActivity}
