import {Button, Grid, IconButton, Typography} from "@material-ui/core"
import React from "react"
import CloseIcon from "@material-ui/icons/Close"

function OfferModalReview({onCancel, onSubmit, onBack, offerData}) {
	return (
		<Grid container direction="column" spacing={3}>
			<Grid container direction="row" spacing={3} style={{alignItems: "center", justifyContent: "flex-start"}}>
				<Grid item xs={9} sm={10} style={{paddingLeft: "12px", paddingTop: "18px"}}>
					<Typography variant="h5" style={{fontWeight: "bold", lineHeight: 1.3}} align="left">
						Review Offer
					</Typography>
				</Grid>
				<Grid item xs={3} sm={2} style={{display: "flex", justifyContent: "flex-end"}}>
					<IconButton aria-label="close" onClick={onCancel}>
						<CloseIcon htmlColor="rgba(50,50,50,0.45)" />
					</IconButton>
				</Grid>
			</Grid>
			<Grid item style={{paddingLeft: "6px"}}>
				<Typography variant="h6" align="left" color="textSecondary" style={{lineHeight: "normal"}}>
					{`Are you sure you want to create an offer of ${offerData.DiscountPercentage}?`}
				</Typography>
			</Grid>
			<Grid item style={{paddingLeft: "6px"}}>
				<hr />
			</Grid>
			<Grid item style={{padding: "2px 2px 2px 6px"}}>
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
						<Typography variant="subtitle1" align="left" color="textSecondary">
							Pay until:
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1" align="right" color="textSecondary">
							{offerData.PayUntil}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{padding: "2px 2px 2px 6px"}}>
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
						<Typography variant="subtitle1" align="left" color="textSecondary">
							Full balance:
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1" align="right" color="textSecondary">
							{offerData.FullBalance}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{padding: "2px 2px 2px 6px"}}>
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
						<Typography variant="subtitle1" align="left" color="textSecondary">
							Discounted amount:
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1" align="right" color="textSecondary">
							{offerData.DiscountedAmount}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{padding: "2px 2px 2px 6px"}}>
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
						<Typography variant="subtitle1" align="left" color="textSecondary">
							Amount to pay:
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1" align="right">
							<strong>{offerData.AmountToPay}</strong>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{paddingTop: "12px"}}>
				<Button fullWidth type="button" onClick={onSubmit}>
					{`Create discount of ${offerData.DiscountPercentage}`}
				</Button>
			</Grid>
			<Grid item>
				<Button fullWidth type="button" variant="outlined" onClick={onBack}>
					Back
				</Button>
			</Grid>
		</Grid>
	)
}

export {OfferModalReview}
