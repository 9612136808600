import React from "react"
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core"
import {ExpandMore as ExpandMoreIcon} from "@material-ui/icons"

import eSignConsentRevokedIcon from "./icons/eSignConsentRevoked.svg"

function ESIGNConsentRevokedActivity(panelId, {Title, HappenedAt, Payload}, expanded, handleExpand, classes) {
	return (
		<Accordion expanded={expanded} onChange={handleExpand(panelId)}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Grid style={{padding: "0 2px"}}>
					<img src={eSignConsentRevokedIcon} width={20} />
				</Grid>
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{expanded && (
					<div className={classes.activityContent}>
						<div>
							<Typography className={classes.activityContentLabel}>Revoked at:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.RevokedAt}</Typography>
						</div>
						<div>
							<Typography className={classes.activityContentLabel}>Revoked via:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.RevokedVia}</Typography>
						</div>
						<div>
							<Typography className={classes.activityContentLabel}>Agent Email:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.AgentEmail}</Typography>
						</div>
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export {ESIGNConsentRevokedActivity}
