import {Accordion, AccordionSummary, Typography} from "@material-ui/core"
import {HighlightOff as DigitalCommConsentDeniedIcon} from "@material-ui/icons"
import React from "react"
import styled from "styled-components"

function DigitalCommunicationConsentDeniedActivity(panelId, {Title, HappenedAt}, expanded, handleExpand, classes) {
	return (
		<AccordionNoContent expanded={false}>
			<AccordionSummary>
				<DigitalCommConsentDeniedIcon color="primary" />
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt}
				</Typography>
			</AccordionSummary>
		</AccordionNoContent>
	)
}

const AccordionNoContent = styled(Accordion)`
	.MuiAccordionSummary-content {
		cursor: default;
	}
`

export {DigitalCommunicationConsentDeniedActivity}
