import React, {useState, useEffect, createContext, useContext} from "react"
import {contextUpdates} from "@frontapp/plugin-sdk"
import {useOktaAuth} from "@okta/okta-react"

const isEmbedded = () => {
	try {
		return window.self !== window.top
	} catch (e) {
		return true
	}
}

function useAgent() {
	const [agent, setAgent] = useState([])
	const oktaObject = useOktaAuth()
	const oktaAuth = oktaObject?.oktaAuth

	const flexAgent = useContext(AgentContext)

	useEffect(() => {
		if (isEmbedded()) {
			const subscription = contextUpdates.subscribe(({teammate}) => {
				if (teammate.email && !agent.length) {
					setAgent([teammate.email, teammate.name])
				}
			})
			return () => {
				subscription.unsubscribe()
			}
		} else {
			oktaAuth?.getUser().then((user) => {
				if (!agent.length) {
					setAgent([user.email, user.name])
				}
			})
		}
	}, [agent, oktaAuth])

	return agent.length > 0 ? agent : flexAgent
}

const AgentContext = createContext([])

const AgentProvider = ({children}) => {
	const [agent, setAgent] = useState([])

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search)
		const agentEmail = urlSearchParams.get("agent_email")
		const agentName = urlSearchParams.get("agent_name")
		if (agentEmail && agentName) {
			setAgent([agentEmail, agentName])
		}
	}, [])

	return <AgentContext.Provider value={agent}>{children}</AgentContext.Provider>
}

export {useAgent, AgentProvider}
