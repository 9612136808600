import React, {useRef, useState, useEffect} from "react"
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Typography,
	Tooltip,
} from "@material-ui/core"
import {
	ExpandMore as ExpandMoreIcon,
	DeleteForever as DeleteForeverIcon,
	Warning as WarningIcon,
	ErrorOutline as ErrorIcon,
} from "@material-ui/icons"
import {orange, yellow} from "@material-ui/core/colors"
import {Truncated} from "@indebted/components/Truncated"
import {api} from "@indebted/api"
import {useAgent} from "@indebted/hooks/Agent"
import {useNotification} from "@indebted/components/Notification"
import styled from "styled-components"
import {colors} from "@indebted/theme"
import {CopyButton} from "@indebted/components/CopyButton"
import {EmailStatusIcon} from "@indebted/components/EmailStatusIcon"

import {ActivePaymentPlanDepricated} from "./ActivePaymentPlan"
import {ContactInstructions} from "./ContactInstructions"
import {ESIGNConsentRequestModal} from "./ESIGNConsentRequestModal"
import {ESIGNConsentRevokeModal} from "./ESIGNConsentRevokeModal"
import {CustomerRemoveExternalEmailLeadModal} from "./CustomerRemoveExternalEmailLeadModal"

var instructionsSectionExpanded = true

function Overview({customer, reload}) {
	const [instructionsExpanded, setInstructionsExpanded] = useState(instructionsSectionExpanded)
	const [emailInput, setEmailInput] = useState(false)
	const [phoneInput, setPhoneInput] = useState(false)
	const [addressForm, setAddressForm] = useState(false)
	const emailInputEl = useRef(null)
	const phoneInputEl = useRef(null)
	const line1InputEl = useRef(null)
	const line2InputEl = useRef(null)
	const cityInputEl = useRef(null)
	const stateInputEl = useRef(null)
	const postcodeInputEl = useRef(null)
	const countryInputEl = useRef(null)
	const [hoveredEmailIndex, setHoveredEmailIndex] = useState(null)
	const [hoveredEmailLeadIndex, setHoveredEmailLeadIndex] = useState(null)

	const [agentEmail] = useAgent()
	const {notification} = useNotification()
	const [paymentLink, setPaymentLink] = useState("")

	useEffect(() => {
		if (emailInput) {
			emailInputEl.current.focus()
		}
	}, [emailInput])

	useEffect(() => {
		if (phoneInput) {
			phoneInputEl.current.focus()
		}
	}, [phoneInput])

	useEffect(() => {
		if (addressForm) {
			line1InputEl.current.focus()
		}
	}, [addressForm])

	const handleAddEmail = () => {
		setEmailInput(true)
		setPhoneInput(false)
		setAddressForm(false)
	}

	const handleAddPhone = () => {
		setPhoneInput(true)
		setEmailInput(false)
		setAddressForm(false)
	}

	const handleChangeAddress = () => {
		setAddressForm(true)
		setPhoneInput(false)
		setEmailInput(false)
	}

	const handleCancelAddEmail = () => {
		setEmailInput(false)
	}

	const handleCancelAddPhone = () => {
		setPhoneInput(false)
	}

	const handleCancelChangeAddress = () => {
		setAddressForm(false)
	}

	const handleRemoveEmail = (email) => {
		api.customer
			.removeEmail(customer.ID, {
				Email: email,
				AgentEmail: agentEmail,
			})
			.then(() => {
				notification.success(
					`Email removed successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				notification.error(error.Message)
			})
	}

	const handleRemovePhone = (phone) => {
		api.customer
			.removePhone(customer.ID, {
				Phone: phone,
				AgentEmail: agentEmail,
			})
			.then(() => {
				notification.success(
					`Phone number removed successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				notification.error(error.Message)
			})
	}

	const handleMouseEnterEmail = (index) => {
		setHoveredEmailIndex(index)
	}

	const handleMouseLeaveEmail = () => {
		setHoveredEmailIndex(null)
	}

	const handleMouseEnterEmailLead = (index) => {
		setHoveredEmailLeadIndex(index)
	}

	const handleMouseLeaveEmailLead = () => {
		setHoveredEmailLeadIndex(null)
	}

	const submitAddEmail = () => {
		setEmailInput(false)
		api.customer
			.addEmail(customer.ID, {
				Email: emailInputEl.current.value,
				AgentEmail: agentEmail,
			})
			.then(() => {
				notification.success(
					`Email added successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				notification.error(error.Message)
			})
	}

	const submitChangeAddress = () => {
		setAddressForm(false)
		api.customer
			.changeAddress(customer.ID, {
				Line1: line1InputEl.current.value,
				Line2: line2InputEl.current.value,
				City: cityInputEl.current.value,
				State: stateInputEl.current.value,
				Postcode: postcodeInputEl.current.value,
				Country: countryInputEl.current.value,
				AgentEmail: agentEmail,
			})
			.then(() => {
				notification.success(
					`Address updated successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				notification.error(error.Message)
			})
	}

	const submitAddPhone = () => {
		setPhoneInput(false)
		api.customer
			.addPhone(customer.ID, {
				Phone: phoneInputEl.current.value,
				AgentEmail: agentEmail,
			})
			.then(() => {
				notification.success(
					`Phone number added successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 500)
			})
			.catch((error) => {
				notification.error(error.Message)
			})
	}

	const handleGeneratePaymentLink = () => {
		api.customer.generatePaymentLink(customer.ID).then((response) => {
			setPaymentLink(response.Message)
		})
	}

	const handleInstructionsExpanded = () => {
		setInstructionsExpanded(!instructionsExpanded)
		instructionsSectionExpanded = !instructionsSectionExpanded
	}

	return (
		<>
			{customer.ContactInstructions.length > 0 && (
				<Accordion expanded={instructionsExpanded} onChange={handleInstructionsExpanded}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<WarningIcon style={{color: orange[500]}} />
						<ContactInstructionsTitle variant="body1" color="textPrimary" display="block">
							<b>Contact Instructions</b>
						</ContactInstructionsTitle>
					</AccordionSummary>

					<AccordionDetails>
						<ContactInstructions instructions={customer.ContactInstructions}></ContactInstructions>
					</AccordionDetails>
				</Accordion>
			)}
			<Table>
				<TableBody>
					{customer.ActivePaymentPlans.map((paymentPlan) => (
						<TableRow key={paymentPlan.ID}>
							<TableCell>
								<ActivePaymentPlanDepricated paymentPlan={paymentPlan} reload={reload} />
							</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell>
							<Typography variant="caption" color="textSecondary" display="block">
								Client
							</Typography>
							<Typography variant="body2" display="block">
								<ClientName customer={customer} />
							</Typography>
						</TableCell>
					</TableRow>

					<TableRow>
						<TableCell>
							<Typography variant="caption" color="textSecondary" display="block">
								Customer Reference
							</Typography>
							<Typography variant="body2" display="block">
								<CopyButton content={customer.CustomerRef} />
								<Truncated text={customer.CustomerRef} size={36} />
							</Typography>
						</TableCell>
					</TableRow>

					{customer.IdentificationDocument && (
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Identification Document
								</Typography>
								<Typography variant="body2" display="block">
									<Truncated text={customer.IdentificationDocument} size={36} />
								</Typography>
							</TableCell>
						</TableRow>
					)}

					<TableRow>
						<TableCell>
							<Typography variant="caption" color="textSecondary" display="block">
								Date of Birth
							</Typography>
							<Typography variant="body2" display="block">
								<CopyButton content={customer.DOB} />
								{customer.DOB}
							</Typography>
						</TableCell>
					</TableRow>

					{customer.CommsOptOutDates &&
						customer.CommsOptOutDates.map((optOut, i) => (
							<TableRow key={i}>
								<TableCell>
									<Typography variant="caption" color="textSecondary" display="block">
										{optOut.Label}
									</Typography>
									<Typography variant="body2" display="block">
										{optOut.Display}
									</Typography>
								</TableCell>
							</TableRow>
						))}

					<TableRow>
						<TableCell>
							{customer.EmailsWithDetails.length > 0 && (
								<DivWithPaddingBottom>
									<Typography variant="caption" color="textSecondary" display="block">
										Emails
									</Typography>

									{customer.EmailsWithDetails.map((emailWithDetails, i) => (
										<EmailBox
											key={i}
											onMouseEnter={() => handleMouseEnterEmail(i)}
											onMouseLeave={handleMouseLeaveEmail}
										>
											<Typography variant="body2">
												<CopyButton content={emailWithDetails.Value} />
												{emailWithDetails.Value}
												<EmailStatusIcon
													title={emailWithDetails.Status.Description}
													icon={emailWithDetails.Status.Icon}
												></EmailStatusIcon>
											</Typography>

											{hoveredEmailIndex === i && (
												<RemoveButton
													size="small"
													onClick={() => handleRemoveEmail(emailWithDetails.Value)}
												>
													<DeleteForeverIcon />
												</RemoveButton>
											)}
										</EmailBox>
									))}
								</DivWithPaddingBottom>
							)}
							<>
								{!emailInput && (
									<Link component="button" variant="body2" onClick={handleAddEmail}>
										Add email
									</Link>
								)}
								{emailInput && (
									<>
										<DemographicTextField
											label="Email"
											size="small"
											fullWidth
											inputRef={emailInputEl}
											InputLabelProps={{
												shrink: true,
											}}
											onKeyUp={(event) => {
												event.preventDefault()
												if (event.key === "Enter") {
													submitAddEmail()
												}
												if (event.key === "Escape") {
													handleCancelAddEmail()
												}
											}}
										/>
										<RocketButtonsBox display="flex" justifyContent="flex-end">
											<RocketButton
												size="small"
												color="primary"
												disableElevation={true}
												onClick={submitAddEmail}
											>
												Save
											</RocketButton>
											<RocketButton
												size="small"
												color="default"
												disableElevation={true}
												onClick={handleCancelAddEmail}
											>
												Cancel
											</RocketButton>
										</RocketButtonsBox>
									</>
								)}
							</>
						</TableCell>
					</TableRow>

					{customer.EmailLeads && customer.EmailLeads.length > 0 && (
						<TableRow>
							<ExternalEmailLeadItem>
								<Box display="flex" justifyContent="flex-start" alignItems="center">
									<Tooltip title="Do not use as a verification point" placement="top-start">
										<Box display="flex">
											<ExternalEmailLeadItemIcon />
											<ExternalEmailLeadItemTitle variant="caption" color="textSecondary">
												External email leads
											</ExternalEmailLeadItemTitle>
										</Box>
									</Tooltip>
								</Box>

								{customer.EmailLeads.map((emailLead, i) => (
									<EmailLeadBox
										key={i}
										onMouseEnter={() => handleMouseEnterEmailLead(i)}
										onMouseLeave={handleMouseLeaveEmailLead}
									>
										<Typography key={i} variant="body2">
											<CopyButton content={emailLead} />
											{emailLead}
										</Typography>

										{hoveredEmailLeadIndex === i && (
											<CustomerRemoveExternalEmailLeadModal
												customerID={customer.ID}
												emailLead={emailLead}
												agentEmail={agentEmail}
												reload={reload}
												reasons={customer.EmailLeadRemovalReasons}
											/>
										)}
									</EmailLeadBox>
								))}
							</ExternalEmailLeadItem>
						</TableRow>
					)}

					{customer.Phones.length > 0 && (
						<>
							{customer.Phones.map((phone, i) => (
								<TableRow key={i}>
									<TableCell>
										<Typography variant="caption" color="textSecondary" display="block">
											Phone number
										</Typography>
										<Box display="flex" justifyContent="space-between" alignItems="center">
											<Typography variant="body2">
												<CopyButton content={phone} />
												{phone}
											</Typography>
											<RemoveButton size="small" onClick={() => handleRemovePhone(phone)}>
												<DeleteForeverIcon />
											</RemoveButton>
										</Box>
									</TableCell>
								</TableRow>
							))}
						</>
					)}
					<>
						{!phoneInput && (
							<TableRow>
								<TableCell>
									<Link component="button" variant="body2" onClick={handleAddPhone}>
										Add phone number
									</Link>
								</TableCell>
							</TableRow>
						)}
						{phoneInput && (
							<TableRow>
								<TableCell valign="middle">
									<DemographicTextField
										label="Phone number"
										size="small"
										fullWidth
										inputRef={phoneInputEl}
										InputLabelProps={{
											shrink: true,
										}}
										onKeyUp={(event) => {
											event.preventDefault()
											if (event.key === "Enter") {
												submitAddPhone()
											}
											if (event.key === "Escape") {
												handleCancelAddPhone()
											}
										}}
									/>
									<RocketButtonsBox display="flex" justifyContent="flex-end">
										<RocketButton
											size="small"
											color="primary"
											disableElevation={true}
											onClick={submitAddPhone}
										>
											Save
										</RocketButton>
										<RocketButton
											size="small"
											color="dafault"
											disableElevation={true}
											onClick={handleCancelAddPhone}
										>
											Cancel
										</RocketButton>
									</RocketButtonsBox>
								</TableCell>
							</TableRow>
						)}
					</>

					{customer.Address && (
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Address
								</Typography>
								<Typography variant="body2" display="block">
									<CopyButton content={customer.Address} />
									{customer.Address}
								</Typography>
							</TableCell>
						</TableRow>
					)}
					<>
						{!addressForm && (
							<TableRow>
								<TableCell>
									<Link component="button" variant="body2" onClick={handleChangeAddress}>
										Edit address
									</Link>
								</TableCell>
							</TableRow>
						)}
						{addressForm && (
							<TableRow>
								<TableCellAddress>
									<AddressFormInput
										label="Line 1"
										size="small"
										fullWidth
										inputRef={line1InputEl}
										InputLabelProps={{
											shrink: true,
										}}
										onKeyUp={(event) => {
											event.preventDefault()
											if (event.key === "Escape") {
												handleCancelChangeAddress()
											}
										}}
									/>
									<AddressFormInput
										label="Line 2"
										size="small"
										fullWidth
										inputRef={line2InputEl}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<AddressFormInput
										label="City"
										size="small"
										fullWidth
										inputRef={cityInputEl}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<AddressFormInput
										label="State"
										size="small"
										fullWidth
										inputRef={stateInputEl}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<AddressFormInput
										label="Postcode"
										size="small"
										fullWidth
										inputRef={postcodeInputEl}
										InputLabelProps={{
											shrink: true,
										}}
										onKeyUp={(event) => {
											event.preventDefault()
											if (event.key === "Enter") {
												submitChangeAddress()
											}
										}}
									/>
									<AddressFormInput
										label="Country"
										size="small"
										value={customer.CountryForAddressUpdate}
										disabled
										fullWidth
										inputRef={countryInputEl}
										InputLabelProps={{
											shrink: true,
										}}
										onKeyUp={(event) => {
											event.preventDefault()
											if (event.key === "Enter") {
												submitChangeAddress()
											}
										}}
									/>

									<RocketButtonsBox display="flex" justifyContent="flex-end">
										<RocketButton
											size="small"
											color="primary"
											disableElevation={true}
											onClick={submitChangeAddress}
										>
											Save
										</RocketButton>
										<RocketButton
											size="small"
											color="default"
											disableElevation={true}
											onClick={handleCancelChangeAddress}
										>
											Cancel
										</RocketButton>
									</RocketButtonsBox>
								</TableCellAddress>
							</TableRow>
						)}
					</>

					{customer.LocalTime && (
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Customer local time
								</Typography>
								<Typography variant="body2" display="block">
									<CopyButton content={customer.LocalTime} />
									{customer.LocalTime}
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{customer.NextCompliantTimeRangeInViewerLocalTime && (
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Next compliant time to communicate (in viewer local time)
								</Typography>
								<Typography variant="body2" display="block">
									<CopyButton content={customer.NextCompliantTimeRangeInViewerLocalTime} />
									{customer.NextCompliantTimeRangeInViewerLocalTime}
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{customer.ESIGNConsentGrantedAt && (
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									E-Sign consent
								</Typography>
								<Typography variant="body2" display="block">
									Consent granted ({customer.ESIGNConsentGrantedAt}){" "}
									<ESIGNConsentRevokeModal customerID={customer.ID} reload={reload} />
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{!customer.ESIGNConsentGrantedAt &&
						customer.ESIGNConsentRequired &&
						!customer.ESIGNConsentRequestedAt && (
							<TableRow>
								<TableCell>
									<Typography variant="caption" color="textSecondary" display="block">
										E-Sign consent
									</Typography>
									<Typography variant="body2" display="block">
										<ESIGNConsentRequestModal
											customerID={customer.ID}
											reload={reload}
											linkText="Request consent"
										/>
									</Typography>
								</TableCell>
							</TableRow>
						)}
					{!customer.ESIGNConsentGrantedAt && customer.ESIGNConsentRequestedAt && (
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									E-Sign consent
								</Typography>
								<Typography variant="body2" display="block">
									E-Sign request sent (pending since {customer.ESIGNConsentRequestedAt}){" "}
									<ESIGNConsentRequestModal
										customerID={customer.ID}
										reload={reload}
										linkText="Resend E-Sign request"
									/>
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{customer.AllowGeneratePaymentLink && (
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Payment Link
								</Typography>
								<Typography variant="body2" display="block">
									{paymentLink ? (
										<>
											<CopyButton content={paymentLink} />
											{paymentLink}
										</>
									) : (
										<Link component="button" variant="body2" onClick={handleGeneratePaymentLink}>
											Generate
										</Link>
									)}
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{customer.PreferredLanguage && (
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Preferred Language
								</Typography>
								<Typography variant="body2" display="block">
									<CopyButton content={customer.PreferredLanguage} />
									{customer.PreferredLanguage}
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{customer.ContactFrequencyRules && (
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Contact Frequency Rules
								</Typography>
								{customer.ContactFrequencyRules.map((rule, i) => (
									<Typography key={i} variant="body2" display="block">
										{rule}
									</Typography>
								))}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</>
	)
}

function ClientName({customer}) {
	if (customer.ClientDisplayName) {
		return (
			<>
				<Box color="text.disabled" style={{textDecoration: "line-through"}}>
					{customer.ClientName}
				</Box>{" "}
				{customer.ClientDisplayName}
			</>
		)
	}
	return <>{customer.ClientName}</>
}

const DemographicTextField = styled(TextField)`
	.MuiInput-input {
		font-size: 14px;
	}
`

const TableCellAddress = styled(TableCell)`
	.MuiInput-input {
		font-size: 14px;
	}
	.MuiSelect-select {
		min-width: 100px;
	}
`

const AddressFormInput = styled(TextField)`
	margin-top: 1em;
`

const RocketButtonsBox = styled(Box)`
	margin-top: 0.75em;
	* + * {
		margin-left: 1em;
	}
`

const RocketButton = styled(Button)`
	.MuiButton-label {
		text-transform: none;
		font-weight: 500;
	}
`

const RemoveButton = styled(IconButton)`
	color: ${colors.boulderGrey};
`

const ContactInstructionsTitle = styled(Typography)`
	padding-left: 0.5em;
`

const ExternalEmailLeadItem = styled(TableCell)`
	background: ${yellow["100"]};
`

const ExternalEmailLeadItemIcon = styled(ErrorIcon)`
	font-size: 1.4em;
`

const ExternalEmailLeadItemTitle = styled(Typography)`
	padding-left: 0.5em;
`

const EmailBox = styled(Box)`
	margin-top: 5px;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	height: 40px;
	justify-content: space-between;
	align-items: center;
	&:hover {
		background-color: rgba(71, 76, 222, 0.21);
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
`

const EmailLeadBox = styled(Box)`
	margin-top: 5px;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	height: 40px;
	justify-content: space-between;
	align-items: center;
	&:hover {
		background-color: rgba(254, 190, 66, 0.52);
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
`
const DivWithPaddingBottom = styled.div`
	padding-bottom: 10px;
`
export {Overview}
