import {Tooltip} from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import AlarmOnIcon from "@material-ui/icons/AlarmOn"
import ScheduleIcon from "@material-ui/icons/Schedule"
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend"
import BlockIcon from "@material-ui/icons/Block"
import React from "react"
import styled, {css} from "styled-components"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"

function AccountStatusIcon({status, fontSize = "small", ...iconProps}) {
	const map = {
		PaymentBeingProcessed: (
			<Tooltip title="Payment being processed" placement="right">
				<StyledAlarmOnIcon fontSize={fontSize} {...iconProps} />
			</Tooltip>
		),
		InPaymentPlan: (
			<Tooltip title="In payment plan" placement="right">
				<StyledAlarmOnIcon fontSize={fontSize} {...iconProps} />
			</Tooltip>
		),
		Settled: (
			<Tooltip title="Settled" placement="right">
				<StyledCheckCircleIcon fontSize={fontSize} {...iconProps} />
			</Tooltip>
		),
		Disputed: (
			<Tooltip title="Disputed" placement="right">
				<StyledWarningIcon fontSize={fontSize} {...iconProps} />
			</Tooltip>
		),
		Recalled: (
			<Tooltip title="Recalled" placement="right">
				<StyledWarningIcon {...iconProps} />
			</Tooltip>
		),
		CustomerCaseEscalated: (
			<Tooltip title="Customer case escalated" placement="right">
				<StyledWarningIcon {...iconProps} />
			</Tooltip>
		),
		PendingInitialBankruptcyScrub: (
			<Tooltip title="Pending initial bankruptcy scrub" placement="right">
				<StyledWarningIcon {...iconProps} />
			</Tooltip>
		),
		BankruptcyScrubDetected: (
			<Tooltip title="Bankruptcy scrub detected" placement="right">
				<StyledWarningIcon {...iconProps} />
			</Tooltip>
		),
		PendingInitialServiceMembersScrub: (
			<Tooltip title="Pending initial service members scrub" placement="right">
				<StyledWarningIcon {...iconProps} />
			</Tooltip>
		),
		ServiceMembersScrubDetected: (
			<Tooltip title="Service members scrub detected" placement="right">
				<StyledWarningIcon {...iconProps} />
			</Tooltip>
		),
		PendingInitialDeceasedScrub: (
			<Tooltip title="Pending deceased scrub" placement="right">
				<StyledWarningIcon {...iconProps} />
			</Tooltip>
		),
		DeceasedScrubDetected: (
			<Tooltip title="Deceased scrub detected" placement="right">
				<StyledWarningIcon {...iconProps} />
			</Tooltip>
		),
		NotificationOfAssignmentPending: (
			<Tooltip title="Notification of assignment pending" placement="right">
				<StyledScheduleIcon {...iconProps} />
			</Tooltip>
		),
		NotificationOfAssignmentPendingDueToEmailScrub: (
			<Tooltip title="Notification of assignment pending due to email scrub" placement="right">
				<StyledScheduleIcon {...iconProps} />
			</Tooltip>
		),
		NotificationOfAssignmentPermanentlyUndelivered: (
			<Tooltip title="Notification of assignment undelivered" placement="right">
				<StyledCancelScheduleSendIcon {...iconProps} />
			</Tooltip>
		),
		NotificationOfAssignmentTemporarilyUndelivered: (
			<Tooltip title="Notification of assignment undelivered" placement="right">
				<StyledCancelScheduleSendIcon {...iconProps} />
			</Tooltip>
		),
		ClosedDueToStatuteOfLimitations: (
			<Tooltip title="Closed due to Statute of Limitations" placement="right">
				<StyledBlockIcon {...iconProps} />
			</Tooltip>
		),
		Outstanding: (
			<Tooltip title="Outstanding" placement="right">
				<StyledMonetizationOnIcon {...iconProps} />
			</Tooltip>
		),
	}

	return map[status] || null
}

const iconCss = css`
	margin-right: 0.5rem;
	vertical-align: middle;
`

const StyledAlarmOnIcon = styled(AlarmOnIcon)`
	${iconCss}
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
	${iconCss}
`

const StyledWarningIcon = styled(WarningIcon)`
	${iconCss}
`

const StyledMonetizationOnIcon = styled(MonetizationOnIcon)`
	${iconCss}
`

const StyledScheduleIcon = styled(ScheduleIcon)`
	${iconCss}
`

const StyledCancelScheduleSendIcon = styled(CancelScheduleSendIcon)`
	${iconCss}
`

const StyledBlockIcon = styled(BlockIcon)`
	${iconCss}
`

export {AccountStatusIcon}
