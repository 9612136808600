import {Box, Typography, TableContainer} from "@material-ui/core"
import React from "react"
import styled from "styled-components"

import {ActivePaymentPlan} from "./ActivePaymentPlan"

const PaymentPlans = ({activePaymentPlans, reload}) =>
	activePaymentPlans.length >= 1 ? <WithActivePlans {...{activePaymentPlans, reload}} /> : <NoActivePlans />

const WithActivePlans = ({activePaymentPlans, reload}) => (
	<StyledTableContainer>
		{activePaymentPlans.map((paymentPlan, index) => (
			<ActivePaymentPlan
				paymentPlan={paymentPlan}
				reload={reload}
				key={paymentPlan.ID}
				activePaymentPlanNumber={index + 1}
			/>
		))}
	</StyledTableContainer>
)

const NoActivePlans = () => (
	<Box m={8}>
		<Typography variant="subtitle1">No active payment plans</Typography>
	</Box>
)

const StyledTableContainer = styled(TableContainer)`
	margin-top: 20px;
`

export {PaymentPlans}
