import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core"
import {ExpandMore as ExpandMoreIcon, AssignmentInd as InfoIcon} from "@material-ui/icons"
import React from "react"

function InformationUpdatedActivity(panelId, {Title, HappenedAt, Payload}, expanded, handleExpand, classes) {
	return (
		<Accordion expanded={expanded} onChange={handleExpand(panelId)}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<InfoIcon color="primary" />
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{expanded && (
					<div className={classes.activityContent}>
						<div>
							<Typography className={classes.activityContentLabel}>Name:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.Name}</Typography>
						</div>
						<div>
							<Typography className={classes.activityContentLabel}>Date of Birth:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.DOB}</Typography>
						</div>
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export {InformationUpdatedActivity}
