import {makeStyles, Box, Table, TableBody, TableCell, TableRow, Tooltip, Typography} from "@material-ui/core"
import React from "react"

import {TransactionIcon} from "./TransactionIcon"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: "33.33%",
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
}))

function List({Transactions}) {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Table>
				<TableBody>
					{Transactions.map(({Amount, Date, Time, Type, Description}, index) => (
						<TableRow key={index}>
							<TableCell>
								<Box display="flex">
									<TransactionIcon type={Type} />
									<Typography variant="body2">{Description}</Typography>
								</Box>
							</TableCell>
							<TableCell>
								<Box display="flex" justifyContent="end">
									<Box mr={2}>
										<Tooltip title={Time} placement="left">
											<Typography variant="caption" color="textSecondary">
												{Date}
											</Typography>
										</Tooltip>
									</Box>
									<Typography variant="body2">{Amount}</Typography>
								</Box>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	)
}

export {List}
