import React from "react"
import {useNavigate} from "react-router-dom"
import {theme} from "@indebted/theme"
import {CssBaseline, MuiThemeProvider} from "@material-ui/core"
import {NotificationProvider} from "@indebted/components/Notification"
import {AgentProvider} from "@indebted/hooks/Agent"
import {Security} from "@okta/okta-react"
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js"

import {Pages} from "./Pages"
import {config} from "./config"

const isEmbedded = () => {
	try {
		return window.self !== window.top
	} catch (e) {
		return true
	}
}

const SecurityWrapper = ({children}) => {
	const navigate = useNavigate()

	if (isEmbedded()) {
		return children
	}

	const restoreOriginalUri = (_oktaAuth, originalUri) => {
		navigate(toRelativeUrl(originalUri || "/", window.location.origin))
	}

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			{children}
		</Security>
	)
}

const oktaAuth = new OktaAuth(config.oktaAuth)

const App = () => {
	window.onbeforeunload = () => {
		localStorage.setItem("lastpage", `${location.pathname}${location.search}`)
		return null
	}

	return (
		<AgentProvider>
			<SecurityWrapper>
				<MuiThemeProvider theme={theme}>
					<CssBaseline />
					<NotificationProvider>
						<Pages />
					</NotificationProvider>
				</MuiThemeProvider>
			</SecurityWrapper>
		</AgentProvider>
	)
}

export {App}
