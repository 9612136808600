import React from "react"
import styled from "styled-components"
import {Tabs as MaterialTabs, Tab} from "@material-ui/core"
import {useLocation, useNavigate} from "react-router-dom"

function Tabs({index, customer}) {
	const location = useLocation()
	const navigate = useNavigate()

	return (
		<MaterialTabs value={index} variant="scrollable" scrollButtons="on">
			<AlignedTab label="Overview" onClick={() => navigate(`/customers/${customer.ID}${location.search}`)} />
			<AlignedTab label="Notes" onClick={() => navigate(`/customers/${customer.ID}/notes${location.search}`)} />
			<AlignedTab
				label="Accounts"
				onClick={() => navigate(`/customers/${customer.ID}/accounts${location.search}`)}
			/>
			<AlignedTab
				label="Transactions"
				onClick={() => navigate(`/customers/${customer.ID}/transactions${location.search}`)}
			/>
			<AlignedTab
				label="Payment Plans"
				onClick={() => navigate(`/customers/${customer.ID}/paymentplans${location.search}`)}
			/>
			<AlignedTab
				label="Activities"
				onClick={() => navigate(`/customers/${customer.ID}/activities${location.search}`)}
			/>
		</MaterialTabs>
	)
}

const AlignedTab = styled(Tab)`
	min-width: auto;
	max-width: auto;
	padding: 6px 12px;
`

export {Tabs}
