import React from "react"
import {Box, Grid, TableCell, Tooltip, Typography, Table, TableBody, TableRow, TableHead} from "@material-ui/core"
import styled from "styled-components"
import {colors} from "@indebted/theme"
import {CopyButton} from "@indebted/components/CopyButton"

import {PaymentPlanMenu} from "./PaymentPlanMenu"

function ActivePaymentPlanDepricated({paymentPlan, reload}) {
	return (
		<div>
			<Typography variant="caption" color="textSecondary" display="block">
				Active Payment Plan
			</Typography>
			<Tooltip title={`Start: ${paymentPlan.StartDate}, End: ${paymentPlan.EndDate}`} placement="right">
				<Grid container key={paymentPlan.ID} alignItems="center">
					<Grid item style={{flexGrow: 1}}>
						<Typography variant="body2">
							{paymentPlan.InstalmentAmount} {paymentPlan.Frequency}
						</Typography>
					</Grid>
					<Grid item>
						<PaymentPlanMenu paymentPlanID={paymentPlan.ID} reload={reload} />
					</Grid>
				</Grid>
			</Tooltip>
		</div>
	)
}

function ActivePaymentPlan({paymentPlan, reload, activePaymentPlanNumber}) {
	return (
		<Table>
			<TableHead>
				<TableRow>
					<PaymentPlanHeader>
						<Grid container key={paymentPlan.ID} alignItems="center">
							<Grid item style={{flexGrow: 1}}>
								<Typography variant="subtitle1" color="textPrimary" background="textPrimary">
									Active payment plan {activePaymentPlanNumber}
								</Typography>
							</Grid>
							<Grid item>
								<PaymentPlanMenu paymentPlanID={paymentPlan.ID} reload={reload} />
							</Grid>
						</Grid>
					</PaymentPlanHeader>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<TableCell>
						<Typography variant="caption" color="textSecondary" display="block">
							Instalment amount
						</Typography>
						<Typography variant="body2" display="block">
							{paymentPlan.InstalmentAmount}
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="caption" color="textSecondary" display="block">
							Payment instalment schedule
						</Typography>
						<Typography variant="body2" display="block">
							{paymentPlan.Frequency}
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="caption" color="textSecondary" display="block">
							Payment plan started
						</Typography>
						<Typography variant="body2" display="block">
							{paymentPlan.StartDate}
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="caption" color="textSecondary" display="block">
							Payment plan end date
						</Typography>
						<Typography variant="body2" display="block">
							{paymentPlan.EndDate}
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="caption" color="textSecondary" display="block">
							Next upcoming instalment
						</Typography>
						<Typography variant="body2" display="block">
							{paymentPlan.NextUpcomingInstalmentDate}
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="caption" color="textSecondary" display="block">
							Payment method
						</Typography>
						<Typography variant="body2" display="block">
							{paymentPlan.PaymentMethod}
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="caption" color="textSecondary" display="block">
							Manage Plan URL
						</Typography>
						<Typography variant="body2" display="block">
							<CopyButton content={paymentPlan.ManagePlanURL} />
							{paymentPlan.ManagePlanURL}
						</Typography>
					</TableCell>
				</TableRow>
				{/* For the payment plan settlement campaigns MVP we should cover only customers with one account. */}
				{paymentPlan.Offers.length === 1 && (
					<>
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Settlement Plan Offer status
								</Typography>
								<Box fontWeight={"fontWeightBold"}>{paymentPlan.Offers[0].Eligibility}</Box>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Campaign - Payment Plan Start Date
								</Typography>
								<Typography variant="body2" display="block">
									{paymentPlan.Offers[0].OfferEndDate}
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Campaign - Payment Plan End Date
								</Typography>
								<Typography variant="body2" display="block">
									{paymentPlan.Offers[0].SettlementLimitDate}
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="caption" color="textSecondary" display="block">
									Discount amount
								</Typography>
								<Typography variant="body2" display="block">
									{paymentPlan.Offers[0].DiscountAmount}
								</Typography>
							</TableCell>
						</TableRow>
					</>
				)}
			</TableBody>
		</Table>
	)
}

const PaymentPlanHeader = styled(TableCell)`
	background-color: ${colors.lightGrey};
`

export {ActivePaymentPlanDepricated, ActivePaymentPlan}
