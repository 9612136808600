import React from "react"
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from "@material-ui/core"
import styled, {css} from "styled-components"
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant"
import {TextToHTML} from "@indebted/components/TextToHTML"
import {ExpandMore as ExpandMoreIcon} from "@material-ui/icons"

function AccountDetails({details, itemizationBalanceBreakdown}) {
	return (
		<>
			{details.map(({Label, Value, Warn}, i) => (
				<TableRow key={i}>
					<TableCell colSpan={2}>
						<Typography variant="caption" color="textSecondary" display="block">
							{Label}
						</Typography>
						<Typography variant="body2" display="block">
							<WarnIcon warn={Warn} />
							<TextToHTML text={Value} />
						</Typography>
					</TableCell>
				</TableRow>
			))}
			<TableRow key={details.length}>
				<TableCell colSpan={2} style={{padding: "0px"}}>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="body2" color="textSecondary" display="block">
								Itemization Balance Breakdown
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Table size="none">
								<TableBody>
									{itemizationBalanceBreakdown.map(({Label, Value}, i) => (
										<TableRow key={i}>
											<TableCell>
												<Typography variant="caption" color="textSecondary" display="block">
													{Label}
												</Typography>
												<Typography variant="body2" display="block">
													<TextToHTML text={Value} />
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</AccordionDetails>
					</Accordion>
				</TableCell>
			</TableRow>
		</>
	)
}

function WarnIcon({warn}) {
	if (!warn) {
		return <></>
	}

	return (
		<Tooltip title={warn} placement="right">
			<StyledNotificationImportantIcon fontSize="small" />
		</Tooltip>
	)
}

const iconCss = css`
	margin-right: 0.5rem;
	vertical-align: middle;
	color: red;
`

const StyledNotificationImportantIcon = styled(NotificationImportantIcon)`
	${iconCss}
`

export {AccountDetails}
