import {Typography} from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import {theme} from "@indebted/theme"
import {FiberManualRecord} from "@material-ui/icons"

function ContactInstructions({instructions}) {
	return (
		<ContactInstructionsContainer>
			{instructions.map((instruction, index) => (
				<InstructionContainer key={index}>
					<SpanInlineBlock style={{paddingRight: "10px"}}>
						<BulletPointIcon />
					</SpanInlineBlock>

					<SpanInlineBlock>
						<ContactInstructionsTypography dangerouslySetInnerHTML={{__html: instruction.Content}} />
					</SpanInlineBlock>
				</InstructionContainer>
			))}
		</ContactInstructionsContainer>
	)
}

const ContactInstructionsContainer = styled.div`
	padding-left: 15px;
`

const InstructionContainer = styled.div`
	padding-bottom: 15px;
	display: flex;
`

const SpanInlineBlock = styled.span`
	display: inline-block;
`

const BulletPointIcon = styled(FiberManualRecord)`
	font-size: 10px;
`

const ContactInstructionsTypography = styled(Typography)`
	font-size: ${theme.typography.pxToRem(15)};
	i {
		color: ${theme.palette.text.secondary};
	}
`

export {ContactInstructions}
