import React, {useEffect, useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import {Link as MuiLink, Table, TableBody, TableCell, TableRow} from "@material-ui/core"
import {api} from "@indebted/api"
import {Search} from "@indebted/components/Search"
import {Layout} from "@indebted/components/Layout"

function CustomersPage({location}) {
	const query = new URLSearchParams(location.search).get("q")
	return <Layout>{query ? <WithQuery query={query} /> : <WithoutQuery />}</Layout>
}

function WithoutQuery() {
	return (
		<>
			<Search value={""} loading={false} error={""} />
		</>
	)
}

function WithQuery({query}) {
	const [state, setState] = useState({
		customers: [],
		loading: false,
		error: "",
	})

	const navigate = useNavigate()

	useEffect(() => {
		setState({customers: [], loading: true, error: ""})
		api.customer
			.search(query)
			.then((customers) => {
				setState({customers, loading: false, error: ""})
				if (customers.length === 1) {
					const customer = customers[0]
					let path = `/customers/${customer.ID}`

					if (Object.keys(customer.OutstandingAccountIDs).includes(query)) {
						path += `/accounts/outstanding/${customer.OutstandingAccountIDs[query]}`
					} else if (Object.keys(customer.OtherAccountIDs).includes(query)) {
						path += `/accounts/other/${customer.OtherAccountIDs[query]}`
					}

					navigate(`${path}?q=${encodeURIComponent(query)}`)
				}
			})
			.catch((error) => {
				setState({customers: [], loading: false, error: error.Message})
			})
	}, [query, navigate])

	return (
		<>
			<Search value={query} loading={state.loading} error={state.error} />
			<Table>
				<TableBody>
					{state.customers.map((customer) => (
						<TableRow key={customer.ID}>
							<TableCell>
								<MuiLink
									component={Link}
									to={`/customers/${customer.ID}?q=${encodeURIComponent(
										query,
									)}&back=/customers${encodeURIComponent(location.search)}`}
								>
									{customer.FullName}
								</MuiLink>
							</TableCell>
							<TableCell align="left">{customer.DOB}</TableCell>
							<TableCell align="right">{customer.ClientName}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</>
	)
}

export {CustomersPage}
