export const config = {
	oktaAuth: {
		issuer: "https://indebted.okta.com",
		clientId: process.env.OKTA_CLIENT_ID,
		redirectUri: `${window.location.origin}/login/callback`,
	},
	datadogAuth: {
		clientToken: "pub2b934a28320e9a4d07d0df53de315d70",
		site: "datadoghq.eu",
		service: "rocket",
		env: process.env.ENV,
		version: process.env.COMMIT_REF,
	},
}
