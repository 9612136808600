import React from "react"
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core"
import {ExpandMore as ExpandMoreIcon} from "@material-ui/icons"

import eSignConsentRequestIcon from "./icons/eSignConsentRequest.svg"

function ESIGNConsentRequestedActivity(panelId, {Title, HappenedAt, Payload}, expanded, handleExpand, classes) {
	return (
		<Accordion expanded={expanded} onChange={handleExpand(panelId)}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Grid style={{padding: "0 2px"}}>
					<img src={eSignConsentRequestIcon} width={20} />
				</Grid>
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{expanded && (
					<div className={classes.activityContent}>
						<div>
							<Typography className={classes.activityContentLabel}>Subject:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.Subject}</Typography>
						</div>
						<div>
							<Typography className={classes.activityContentLabel}>Agent Email:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.AgentEmail}</Typography>
						</div>
						<div>
							<Typography className={classes.activityContentLabel}>Customer Email:</Typography>
							<Typography className={classes.activityContentValue}>{Payload.To}</Typography>
						</div>
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export {ESIGNConsentRequestedActivity}
