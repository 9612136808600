import React, {useEffect} from "react"
import {Navigate, Routes, Route, useLocation} from "react-router-dom"
import {LoginCallback} from "@okta/okta-react"
import styled from "styled-components"

import {CustomerPage} from "./CustomerPage"
import {CustomersPage} from "./CustomersPage"
import {RequiredAuth} from "./RequiredAuth"
import {HomePage} from "./HomePage"

const isEmbedded = () => {
	try {
		return window.self !== window.top
	} catch (e) {
		return true
	}
}

function FrontPages({location}) {
	return (
		<Routes>
			<Route index element={<HomePage />} />
			<Route path="/customers" element={<CustomersPage location={location} />} />
			<Route path="/customers/:id/*" element={<CustomerPage location={location} />} />
		</Routes>
	)
}

function OktaPages({location}) {
	return (
		<Routes>
			<Route
				path="/login/callback"
				element={<LoginCallback loadingElement={<h1 id="loading-icon">Loading...</h1>} />}
			/>
			<Route path="/" element={<RequiredAuth />}>
				<Route index element={<Navigate to="/customers" />} />
				<Route path="/customers" element={<CustomersPage location={location} />} />
				<Route path="/customers/:id/*" element={<CustomerPage location={location} />} />
			</Route>
		</Routes>
	)
}

const Style = styled.div`
	height: 100vh;
`

function Pages() {
	const location = useLocation()

	useEffect(() => {
		localStorage.setItem("lastpage", `${location.pathname}${location.search}`)
	}, [location])

	return <Style>{isEmbedded() ? <FrontPages location={location} /> : <OktaPages location={location} />}</Style>
}

export {Pages}
