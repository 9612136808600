import {Button, Grid, IconButton, Typography, TextField} from "@material-ui/core"
import React from "react"
import {styled} from "@mui/material/styles"
import CloseIcon from "@material-ui/icons/Close"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {LocalizationProvider, PickersLayout} from "@mui/x-date-pickers"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3"
import format from "date-fns/format"

function OfferModalForm({onCancel, onCalculate, offerData, error, storedData}) {
	const [percentage, setPercentage] = React.useState()
	const [date, setDate] = React.useState(new Date())

	React.useEffect(() => {
		setDate(new Date(storedData?.date ?? offerData.DefaultEndDate))
	}, [offerData.DefaultEndDate, storedData?.date])

	const onChangePercentage = (e) => {
		e.preventDefault()
		if (e.target.value < offerData.MinPercentage) e.target.value = offerData.MinPercentage
		if (e.target.value > offerData.MaxPercentage) e.target.value = offerData.MaxPercentage
		setPercentage(e.target.value)
	}

	const onChangeDate = (date) => {
		setDate(date)
	}

	return (
		<Grid container direction="column" spacing={3}>
			<Grid container direction="row" spacing={3} style={{alignItems: "center", justifyContent: "flex-start"}}>
				<Grid item xs={9} sm={10} style={{paddingLeft: "12px", paddingTop: "18px"}}>
					<Typography
						variant="h5"
						style={{fontWeight: "bold", lineHeight: 1.3, paddingBottom: "24px"}}
						align="left"
					>
						Settle account with discount
					</Typography>
				</Grid>
				<Grid item xs={3} sm={2} style={{display: "flex", justifyContent: "flex-end"}}>
					<IconButton aria-label="close" onClick={onCancel}>
						<CloseIcon htmlColor="rgba(50,50,50,0.45)" />
					</IconButton>
				</Grid>
			</Grid>
			{error && (
				<Grid item style={{paddingLeft: "6px"}}>
					<Grid container direction="row">
						<Grid item>
							<Typography variant="caption" align="left" color="error">
								{error}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
			<Grid item style={{paddingLeft: "6px"}}>
				<Grid container direction="row" spacing={3}>
					<Grid item xs={4}>
						<TextField
							label="%"
							value={percentage}
							onChange={onChangePercentage}
							variant="outlined"
							autoFocus
							fullWidth
							inputProps={{
								type: "number",
								min: offerData.MinPercentage,
								max: offerData.MaxPercentage,
								suffix: "%",
								step: "0.01",
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<StyledDatePicker
								slots={{
									layout: StyledPickersLayout,
								}}
								value={date}
								onChange={onChangeDate}
								defaultValue={date}
								format="dd MMMM yyyy"
								minDate={new Date(offerData.DefaultEndDate)}
								variant="outlined"
								label="Pay until"
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{paddingLeft: "6px"}}>
				<hr />
			</Grid>
			<Grid item style={{paddingLeft: "6px"}}>
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
						<Typography variant="subtitle1" align="left" color="textSecondary">
							Full balance:
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1" align="right" color="textSecondary">
							{offerData.FullBalance}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Button
					fullWidth
					variant="outlined"
					type="button"
					onClick={() => onCalculate(format(date, offerData.DateFormat), percentage?.toString())}
				>
					Review discount
				</Button>
			</Grid>
		</Grid>
	)
}

const StyledDatePicker = styled(DatePicker)({
	".Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
		borderColor: "rgb(71, 76, 222)",
		color: "rgb(71, 76, 222)",
	},
	".Mui-focused.MuiInputLabel-outlined": {
		color: "rgb(71, 76, 222)",
	},
})

const StyledPickersLayout = styled(PickersLayout)({
	".MuiPickersDay-root.Mui-selected": {
		backgroundColor: "rgb(71, 76, 222)",
		"&:hover": {
			backgroundColor: "rgb(49, 53, 155)",
		},
	},
})

export {OfferModalForm}
