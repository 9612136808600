import {Box, Button, Grid, IconButton, MenuItem, Modal, Paper, Typography} from "@material-ui/core"
import React, {useState} from "react"
import {styled} from "@mui/material/styles"
import {useNotification} from "@indebted/components/Notification"
import {DeleteForever as DeleteForeverIcon} from "@material-ui/icons"
import {colors} from "@indebted/theme"
import Select from "@mui/material/Select"
import {api} from "@indebted/api"

function CustomerRemoveExternalEmailLeadModal({customerID, emailLead, agentEmail, reload, reasons}) {
	const [open, setOpen] = useState(false)
	const {notification} = useNotification()
	const [reason, setReason] = React.useState("")
	const [deleteDisabled, setDeleteDisabled] = React.useState(true)

	const handleReason = (event) => {
		setReason(event.target.value)
		setDeleteDisabled(false)
	}

	const handleGoBack = () => {
		setReason("")
		setDeleteDisabled(true)
		setOpen(false)
	}

	const handleRemoveEmailLead = (reason) => {
		setDeleteDisabled(true)
		api.customer
			.removeEmailLead(customerID, {
				EmailLead: emailLead,
				AgentEmail: agentEmail,
				Reason: reason,
			})
			.then(() => {
				notification.success(
					`External email lead removed successfully. Please wait up to 48 hours for propagation in all systems.`,
				)
				setTimeout(reload, 2000)
			})
			.catch((error) => {
				notification.error(error.Message)
			})
	}

	const menuProps = {
		PaperProps: {
			style: {
				width: "20%", // This makes the dropdown menu use the min-width of the Select component and match the width
			},
		},
	}

	return (
		<>
			<RemoveButton size="small" onClick={() => setOpen(true)}>
				<DeleteForeverIcon />
			</RemoveButton>

			<Modal
				open={open ?? false}
				onClose={() => setOpen(false)}
				aria-labelledby="remove-email-lead-modal-title"
				aria-describedby="remove-email-lead-modal-description"
			>
				<StyledPaper>
					<StyledBox>
						<Title variant="subtitle1">
							You are about to delete an External email lead. Are you sure you want to proceed?
						</Title>

						<EmailText variant="subtitle1">Email: {emailLead}</EmailText>
						<Subtitle variant="subtitle1">Choose a reason</Subtitle>
						<StyledSelect
							key={reason.ID}
							value={reason}
							onChange={handleReason}
							size="small"
							MenuProps={menuProps}
						>
							{reasons &&
								reasons.map((reason) => (
									<StyledMenuItem key={reason.ID} value={reason.ID}>
										{reason.Display}
									</StyledMenuItem>
								))}
						</StyledSelect>
						<ButtonGrid container direction="row" justifyContent="space-between">
							<Grid item>
								<GoBackButton onClick={handleGoBack}>
									<Typography>No, go back</Typography>
								</GoBackButton>
							</Grid>
							<Grid item>
								<DeleteButton
									variant="text"
									disabled={deleteDisabled}
									onClick={() => handleRemoveEmailLead(reason)}
								>
									<Typography>Yes, delete</Typography>
								</DeleteButton>
							</Grid>
						</ButtonGrid>
					</StyledBox>
				</StyledPaper>
			</Modal>
		</>
	)
}

const GoBackButton = styled(Button)`
	padding: 10px 30px;
`

const DeleteButton = styled(Button)`
	padding: 10px 30px;
	color: red;
	&.Mui-disabled {
		color: grey;
	}
`
const ButtonGrid = styled(Grid)`
	padding-top: 20px;
`

const RemoveButton = styled(IconButton)`
	color: ${colors.boulderGrey};
`

const StyledPaper = styled(Paper)`
	padding: 20px 20px 20px 20px;
	position: relative;
	max-width: 450px;
	width: 100%;
	max-height: 95%;
	outline: none;
	overflow: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@media screen and (max-width: 599px) {
		width: 90%;
	}
`
const StyledBox = styled(Box)`
	width: 100%;
`

const StyledSelect = styled(Select)`
	width: 100%;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.05em;
`

const StyledMenuItem = styled(MenuItem)`
	width: 100%;
	white-space: normal;
`

const Title = styled(Typography)`
	font-weight: bold;
	line-height: 19px;
	letter-spacing: 0.05em;
`

const Subtitle = styled(Typography)`
	padding-top: 20px;
	padding-bottom: 10px;
	line-height: 19px;
	letter-spacing: 0.05em;
`

const EmailText = styled(Typography)`
	padding-top: 20px;
	line-height: 19px;
	letter-spacing: 0.05em;
`

export {CustomerRemoveExternalEmailLeadModal}
