import React from "react"
import {Navigate} from "react-router-dom"

function HomePage() {
	const lastPage = localStorage.getItem("lastpage")
	if (lastPage && lastPage !== "/") {
		return <Navigate to={lastPage} noThrow replace />
	}

	return <Navigate to="/customers" noThrow replace />
}

export {HomePage}
