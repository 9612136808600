import React from "react"
import {
	Box,
	Link as MuiLink,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@material-ui/core"
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant"
import styled, {css} from "styled-components"
import {Truncated} from "@indebted/components/Truncated"
import {Link, useLocation} from "react-router-dom"
import {colors} from "@indebted/theme"
import {CopyButton} from "@indebted/components/CopyButton"
import {TextToHTML} from "@indebted/components/TextToHTML"

import {AccountMenu} from "./AccountMenu"
import {AccountStatusIcon} from "./AccountStatusIcon"
import {OtherAccountMenu} from "./OtherAccountMenu"

function AccountList({customer, reload}) {
	const StyledTableRow = customer.TotalOther ? StyledTableRowWithoutLastBorder : TableRow
	return (
		<>
			{customer.AllAccountsBalanceBreakdown.length > 0 && (
				<>
					<Box mt={7.5}>
						{customer.AllAccountsBalanceBreakdown && <Subtitle>Balance Overview</Subtitle>}
						<Table size="small">
							<TableBody>
								{customer.AllAccountsBalanceBreakdown.map((balance, i) => (
									<React.Fragment key={i}>
										<TableRow>
											<TableCell>{balance.Label}</TableCell>
											<TableCell align="right">
												<TextToHTML text={balance.Value} />
											</TableCell>
										</TableRow>
									</React.Fragment>
								))}
							</TableBody>
						</Table>
					</Box>
				</>
			)}

			{customer.WrittenStatuteOfLimitationsDisclosures && (
				<Box mt={7.5}>
					<Subtitle>
						<StyledNotificationImportantIcon fontSize="small" /> Written Statute of Limitations Disclosure
					</Subtitle>
					<Disclosure variant="body2" display="block">
						<CopyButton content={customer.WrittenStatuteOfLimitationsDisclosures} />
						<Truncated text={customer.WrittenStatuteOfLimitationsDisclosures} size={36} />
					</Disclosure>
				</Box>
			)}

			{customer.VerbalStatuteOfLimitationsDisclosures && (
				<Box mt={7.5}>
					<Subtitle>
						<StyledNotificationImportantIcon fontSize="small" /> Verbal Statute of Limitations Disclosure
					</Subtitle>
					<Disclosure variant="body2" display="block">
						{customer.VerbalStatuteOfLimitationsDisclosures}
					</Disclosure>
				</Box>
			)}

			{customer.OutstandingAccounts.length > 0 && (
				<>
					<Box mt={7.5}>
						{customer.TotalOther && <Subtitle>Outstanding accounts</Subtitle>}
						{/* For the payment plan settlement campaigns MVP we should cover only customers with one account. */}
						{customer.OutstandingAccounts[0].PaymentPlanSettlementOffers.length === 1 && (
							<TableContainer mb={7.5}>
								<Table size="small" style={{minWidth: "600px"}}>
									<TableHead>
										<TableRow>
											<TableCell align="center"></TableCell>
											<TableCell align="center">Offer</TableCell>
											<TableCell align="center">Original Amount</TableCell>
											<TableCell align="center">Discount Amount</TableCell>
											<TableCell align="center">Campaign End Date</TableCell>
											<TableCell align="center">Completion Date</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{customer.OutstandingAccounts[0].PaymentPlanSettlementOffers.map((offer) => (
											<TableRow key={offer.AccountID}>
												<TableCell align="center">Settlement Plan Offer</TableCell>
												<TableCell align="center">{offer.DiscountRate}</TableCell>
												<TableCell align="center">{offer.AccountBalance}</TableCell>
												<TableCell align="center">{offer.DiscountAmount}</TableCell>
												<TableCell align="center">{offer.OfferEndDate}</TableCell>
												<TableCell align="center">{offer.SettlementLimitDate}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Box>
					<Box>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Account Ref</TableCell>
									<TableCell align="right">Balance</TableCell>
									<MenuTableCell />
								</TableRow>
							</TableHead>

							<TableBody>
								{customer.OutstandingAccounts.map((account, i) => (
									<React.Fragment key={i}>
										<StyledTableRow>
											<TableCell>
												<Tooltip title="Outstanding" placement="right">
													<AccountStatusIcon status="Outstanding" />
												</Tooltip>
												{account.SOLExpired && (
													<Tooltip title="Statute of Limitations Expired" placement="right">
														<StyledNotificationImportantIcon fontSize="small" />
													</Tooltip>
												)}
												<CopyButton content={account.AccountRef} />
												<AccountLink path="outstanding" account={account}>
													<Truncated text={account.AccountRef} />
												</AccountLink>
											</TableCell>
											<TableCell align="right">{account.Balance}</TableCell>
											<MenuTableCell align="center">
												<AccountMenu account={account} reload={reload} />
											</MenuTableCell>
										</StyledTableRow>
									</React.Fragment>
								))}

								<TableRow>
									<TotalTableCell>Total balance</TotalTableCell>
									<TotalTableCell align="right">{customer.TotalOutstanding}</TotalTableCell>
									<TotalTableCell />
								</TableRow>
							</TableBody>
						</Table>
					</Box>
				</>
			)}

			{customer.PaymentPlanAccounts.length > 0 && (
				<Box mt={7.5}>
					{customer.TotalOther && <Subtitle>Payment plan accounts</Subtitle>}
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Account Ref</TableCell>
								<TableCell align="right">Balance</TableCell>
								<MenuTableCell />
							</TableRow>
						</TableHead>

						<TableBody>
							{customer.PaymentPlanAccounts.map((account, i) => (
								<React.Fragment key={i}>
									<StyledTableRow>
										<OtherAccountTableCell>
											<AccountStatusIcon status="InPaymentPlan" />
											{account.SOLExpired && (
												<Tooltip title="Statute of Limitations Expired" placement="right">
													<StyledNotificationImportantIcon fontSize="small" />
												</Tooltip>
											)}
											<CopyButton content={account.AccountRef} />
											<AccountLink path="payment-plan" account={account}>
												<Truncated text={account.AccountRef} />
											</AccountLink>
										</OtherAccountTableCell>
										<OtherAccountTableCell align="right">{account.Balance}</OtherAccountTableCell>
										<MenuTableCell align="center">
											<OtherAccountMenu account={account} reload={reload} />
										</MenuTableCell>
									</StyledTableRow>
								</React.Fragment>
							))}

							<TableRow>
								<TotalTableCell>Total balance</TotalTableCell>
								<TotalTableCell align="right">{customer.TotalInPaymentPlan}</TotalTableCell>
								<TotalTableCell />
							</TableRow>
						</TableBody>
					</Table>
				</Box>
			)}

			{customer.OtherAccounts.length > 0 && (
				<Box mt={7.5}>
					{customer.TotalOther && <Subtitle>Other accounts</Subtitle>}
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Account Ref</TableCell>
								<TableCell align="right">Balance</TableCell>
								<MenuTableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{customer.OtherAccounts.map((account, i) => (
								<React.Fragment key={i}>
									<StyledTableRow>
										<OtherAccountTableCell>
											<AccountStatusIcon status={account.Status} />
											{account.SOLExpired && (
												<Tooltip title="Statute of Limitations Expired" placement="right">
													<StyledNotificationImportantIcon fontSize="small" />
												</Tooltip>
											)}
											<CopyButton content={account.AccountRef} />
											<AccountLink path="other" account={account}>
												<Truncated text={account.AccountRef} />
											</AccountLink>
										</OtherAccountTableCell>
										<OtherAccountTableCell align="right">{account.Balance}</OtherAccountTableCell>
										<MenuTableCell align="center">
											<OtherAccountMenu account={account} reload={reload} />
										</MenuTableCell>
									</StyledTableRow>
								</React.Fragment>
							))}
							<TableRow>
								<TotalTableCell>Total balance</TotalTableCell>
								<TotalTableCell align="right">{customer.TotalOther}</TotalTableCell>
								<TotalTableCell />
							</TableRow>
						</TableBody>
					</Table>
				</Box>
			)}
		</>
	)
}

function AccountLink({path, account, children}) {
	const location = useLocation()
	const backUrl = `accountBack=${location.pathname}${encodeURIComponent(location.search)}`
	const backQuery = location.search ? `&${backUrl}` : `?${backUrl}`
	return (
		<MuiLink component={Link} to={`${location.pathname}/${path}/${account.ID}${location.search}${backQuery}`}>
			{children}
		</MuiLink>
	)
}

const iconCss = css`
	margin-right: 0.5rem;
	vertical-align: middle;
	color: red;
`

const StyledNotificationImportantIcon = styled(NotificationImportantIcon)`
	${iconCss}
`

const TotalTableCell = styled(TableCell)`
	color: inherit;
	font-size: inherit;
	font-weight: bold;
	border-color: ${colors.black};
	border-top: 1px solid ${colors.black};
`

const Subtitle = styled(Typography).attrs({variant: "subtitle1"})`
	margin: 0.5rem;
	color: ${colors.boulderGrey};
	font-weight: bold;
`

const Disclosure = styled(Typography).attrs({variant: "body2"})`
	margin: 0.5rem;
	white-space: pre-line;
`

const OtherAccountTableCell = styled(TableCell)`
	color: ${colors.boulderGrey};
`

const MenuTableCell = styled(TableCell)`
	padding-left: 0px;
`
const StyledTableRowWithoutLastBorder = styled(TableRow)`
	&:nth-last-child(2) > td {
		border-bottom: 0px;
	}
`

export {AccountList}
