import React from "react"
import styled, {keyframes} from "styled-components"

import {InDebted} from "./InDebted"

function LoadingPage() {
	return (
		<Centered>
			<LoadingIcon width="10rem" />
		</Centered>
	)
}

function LoadingIcon({width = "3rem"}) {
	return (
		<>
			<AnimatedLoadingIcon>
				<InDebted variant="square" width={width} />
			</AnimatedLoadingIcon>
		</>
	)
}

const Centered = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const loadingIconAnimations = keyframes`
	0% { translateY(0); opacity: 1; }
	10%, 40% { opacity: 0; }
	50% { opacity: 0; transform: translateY(-20%); }
	60% { opacity: 1; transform: translateY(0); }
`

const AnimatedLoadingIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	& rect {
		animation: ${loadingIconAnimations} 2s infinite;
	}

	& rect:nth-child(1) {
		animation-delay: 1s;
	}

	& rect:nth-child(2) {
		animation-delay: 0.5s;
	}

	& rect:nth-child(3) {
		animation-delay: 0s;
	}
`

export {LoadingPage, LoadingIcon}
