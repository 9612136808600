import React, {useEffect, useState} from "react"
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography} from "@material-ui/core"
import {ExpandMore as ExpandMoreIcon} from "@material-ui/icons"
import {LoadingPage} from "@indebted/components/Loading"
import {api} from "@indebted/api"
import {BackToSearch} from "@indebted/components/BackToSearch"
import {useLocation} from "react-router-dom"

import {NoActivities} from "./NoActivities"
import {ActivitiesList} from "./ActivitiesList"

function Account({account, accountDetailsComponent}) {
	const [activitiesExpanded, setActivitiesExpanded] = React.useState(false)
	const [activitiesComponent, setActivitiesComponent] = useState(<LoadingPage />)
	const [detailsExpanded, setDetailsExpanded] = React.useState(true)

	useEffect(() => {
		api.account
			.activities(account.ID)
			.then((activities) => {
				if (activities.length === 0) {
					setActivitiesComponent(<NoActivities>No activities</NoActivities>)
				} else {
					setActivitiesComponent(<ActivitiesList activities={activities} />)
				}
			})
			.catch(() => {
				setActivitiesComponent(<NoActivities>Something went wrong</NoActivities>)
			})
	}, [account.ID])

	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const backButton = () => (
		<Box p={3}>
			<Grid container justifyContent="flex-end">
				<BackToSearch backURL={params.get("accountBack")} />
			</Grid>
		</Box>
	)

	const handleExpandDetails = () => {
		setDetailsExpanded(!detailsExpanded)
		setActivitiesExpanded(false)
	}
	const detailsSection = () => (
		<Accordion expanded={detailsExpanded} onChange={handleExpandDetails}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="body1" color="textSecondary" display="block">
					Details
				</Typography>
			</AccordionSummary>

			<AccordionDetails>{accountDetailsComponent}</AccordionDetails>
		</Accordion>
	)

	const handleExpandActivities = () => {
		setActivitiesExpanded(!activitiesExpanded)
		setDetailsExpanded(false)
	}
	const activitiesSection = () => (
		<Accordion expanded={activitiesExpanded} onChange={handleExpandActivities}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="body1" color="textSecondary" display="block">
					Activities
				</Typography>
			</AccordionSummary>

			<AccordionDetails>{activitiesComponent}</AccordionDetails>
		</Accordion>
	)

	return (
		<>
			{backButton()}
			{detailsSection()}
			{activitiesSection()}
		</>
	)
}

export {Account}
