import {Box, Typography} from "@material-ui/core"
import React from "react"

function NoActivities({children}) {
	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<Typography variant="h5" color="textSecondary">
				{children}
			</Typography>
		</Box>
	)
}

export {NoActivities}
