import {api} from "@indebted/api"
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	Link,
	Modal,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@material-ui/core"
import {styled} from "@mui/material/styles"
import React, {useState} from "react"
import {useAgent} from "@indebted/hooks/Agent"
import {useNotification} from "@indebted/components/Notification"

function ESIGNConsentRevokeModal({customerID, reload}) {
	const [open, setOpen] = useState(false)
	const [step, setStep] = useState(1)
	const [data, setData] = useState()
	const [agentEmail] = useAgent()
	const {notification} = useNotification()

	const steps = {
		1: {
			render: ESIGNRevokeConsentConfirmation,
			props: {
				onConfirm: (data) => {
					setStep(step + 1)
					setData(data)
				},
				onCancel: () => {
					setOpen(false)
					setStep(1)
					setData()
				},
			},
		},
		2: {
			render: ESIGNRevokeConsentChannelSelection,
			props: {
				onConfirm: (data) => {
					setStep(step + 1)
					setData(data)
				},
				onCancel: () => {
					setOpen(false)
					setStep(1)
					setData()
				},
			},
		},
		3: {
			render: ESIGNRevokeConsentReview,
			props: {
				onConfirm: () => {
					setTimeout(reload, 1000)
				},
				onCancel: () => {
					setOpen(false)
					setStep(1)
					setData()
				},
				data: data,
				agentEmail: agentEmail,
				customerID: customerID,
				notification: notification,
			},
		},
	}
	const component = steps[step]

	return (
		<>
			<Link component="button" variant="body2" onClick={() => setOpen(true)}>
				Revoke Consent
			</Link>
			<Modal
				open={open ?? false}
				onClose={() => setOpen(false)}
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
			>
				<StyledPaper>
					<component.render {...component.props} />
				</StyledPaper>
			</Modal>
		</>
	)
}
const StyledPaper = styled(Paper)`
	padding: 20px;
	position: relative;
	width: 400px;
	max-height: 95%;
	outline: none;
	overflow: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;

	@media screen and (max-width: 599px) {
		width: 90%;
	}
`

class ESIGNRevokeConsentConfirmation extends React.Component {
	render() {
		return (
			<Box style={{width: "100%"}}>
				<Grid container direction="column" spacing={3}>
					<Grid item>
						<Grid container spacing={2}>
							<Grid item>
								<Typography variant="h5">Are you sure you want to revoke E-Sign consent?</Typography>
							</Grid>
							<Grid item>
								<Grid container direction="row" style={{paddingTop: "10px", paddingBottom: "10px"}}>
									<Grid item xs={1}>
										<Typography>⛔️</Typography>
									</Grid>
									<Grid item xs={11}>
										<Grid container direction="column" spacing={4}>
											<Grid item>
												<WarningText variant="body2">
													Revoking consent will restrict the customer&apos;s access to
													electronic communications.
												</WarningText>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<WarningButton fullWidth onClick={this.props.onConfirm}>
							<Typography variant="body1">Revoke E-Sign consent</Typography>
						</WarningButton>
					</Grid>
					<Grid item>
						<Button fullWidth variant={"outlined"} onClick={this.props.onCancel}>
							<Typography variant="body1">Back</Typography>
						</Button>
					</Grid>
				</Grid>
			</Box>
		)
	}
}

class ESIGNRevokeConsentChannelSelection extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isOther: false,
			showESIGNErrorMessage: false,
		}
	}

	setIsOther(value) {
		this.setState({isOther: value})
	}

	render() {
		return (
			<Box style={{width: "100%"}}>
				<form
					name="ESIGNRevokeConsentForm"
					onSubmit={(e) => {
						e.preventDefault()

						const formData = new FormData(e.target)
						let selectedDate = new Date(formData.get("date-time"))

						if (selectedDate >= new Date()) {
							this.setState({showESIGNErrorMessage: true})
							return
						}
						this.setState({showESIGNErrorMessage: false})

						this.props.onConfirm({
							channel:
								formData.get("channel") === "Other"
									? formData.get("channel-other")
									: formData.get("channel"),
							channelOther: formData.get("channel-other"),
							dateTime: selectedDate.toISOString(),
						})
					}}
				>
					<Grid container direction="column" spacing={3}>
						<Grid item>
							<Grid container spacing={4}>
								<Grid item>
									<Typography variant="h5">
										Just one more step to revoke E&#8209;Sign consent
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="body1">
										How did the customer revoke their E&#8209;Sign consent?
									</Typography>
								</Grid>
								<Grid item>
									<FormControl>
										<StyledFormLabel>Please select one of the following options:</StyledFormLabel>
										<RadioGroup
											defaultValue=""
											name="channel"
											onChange={(e) => {
												this.setIsOther(e.target.value === "Other")
											}}
										>
											<FormControlLabel
												value="Email"
												control={<Radio color="primary" required />}
												label="Email"
											/>
											<FormControlLabel
												value="Phone"
												control={<Radio color="primary" required />}
												label="Phone"
											/>
											<FormControlLabel
												value="SMS"
												control={<Radio color="primary" required />}
												label="SMS"
											/>
											<FormControlLabel
												value="Other"
												control={<Radio color="primary" required />}
												label="Other"
											/>
										</RadioGroup>
									</FormControl>
									{this.state.isOther && (
										<TextField
											name="channel-other"
											label="Description"
											variant="outlined"
											fullWidth
											required
										/>
									)}
								</Grid>
								<Grid item>
									<FormControl>
										<StyledFormLabel>Date and time:</StyledFormLabel>
										<TextField
											name="date-time"
											type="datetime-local"
											InputLabelProps={{
												shrink: true,
											}}
											color="primary"
											required
										/>
									</FormControl>
									<Typography
										variant="body1"
										style={{color: "rgba(0, 0, 0, 0.87)", padding: "8px 0px 8px 0px"}}
									>
										Note: Date and time are in your local time
									</Typography>
								</Grid>
								{this.state.showESIGNErrorMessage && (
									<Grid item xs={12}>
										<StyledAlertGrid container item>
											<Typography variant="caption">Revoked date must be in the past</Typography>
										</StyledAlertGrid>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item>
							<Button fullWidth type="submit">
								<Typography variant="body1">Review</Typography>
							</Button>
						</Grid>
						<Grid item>
							<Button fullWidth variant={"outlined"} onClick={this.props.onCancel}>
								<Typography variant="body1">Cancel</Typography>
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		)
	}
}

const localTimeOptions = {
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
	hour12: true,
	timeZoneName: "short",
}

class ESIGNRevokeConsentReview extends React.Component {
	render() {
		return (
			<Box style={{width: "100%"}}>
				<Grid container direction="column" spacing={3}>
					<Grid item>
						<Grid container direction="column" spacing={4}>
							<Grid item>
								<Typography variant="h5">Revoke E&#8209;Sign consent</Typography>
							</Grid>
							<Grid item>
								<StyledReviewTable>
									<tbody>
										<tr>
											<td>Channel</td>
											<StyledReviewTableData>{this.props.data.channel}</StyledReviewTableData>
										</tr>
										<tr>
											<td>Date and time</td>
											<StyledReviewTableData>
												{Intl.DateTimeFormat("default", localTimeOptions).format(
													new Date(this.props.data.dateTime),
												)}
											</StyledReviewTableData>
										</tr>
									</tbody>
								</StyledReviewTable>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<WarningButton
							fullWidth
							onClick={() => {
								api.customer
									.revokeESIGNConsent(
										this.props.customerID,
										this.props.data.dateTime,
										this.props.data.channel,
										this.props.agentEmail,
									)
									.then(() => {
										this.props.notification.success("E-Sign consent revoked")
										this.props.onConfirm()
									})
									.catch((error) => {
										this.props.notification.error(error.Message)
									})
							}}
						>
							<Typography variant="body1">Revoke</Typography>
						</WarningButton>
					</Grid>
					<Grid item>
						<Button fullWidth variant={"outlined"} onClick={this.props.onCancel}>
							<Typography variant="body1">Cancel</Typography>
						</Button>
					</Grid>
				</Grid>
			</Box>
		)
	}
}

const WarningText = styled(Typography)`
	color: red;
	font-weight: 600;
`
const WarningButton = styled(Button)`
	background-color: rgb(170, 29, 29);

	:hover {
		background-color: rgb(170, 29, 29);
	}
`

const StyledReviewTable = styled("table")`
	width: 100%;
	color: #888282;
`

const StyledReviewTableData = styled("td")`
	text-align: right;
`

const StyledFormLabel = styled(FormControl)`
	color: rgba(0, 0, 0, 0.87);
	font-size: 1rem;
`

const StyledAlertGrid = styled(Grid)`
	border: solid 1px #d7153a;
	border-radius: 5px;
	padding: 5px;

	color: #d7153a;
`

export {ESIGNConsentRevokeModal}
